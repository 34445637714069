import { Box, Button, Card, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../service/api'
import TableHeader from '../../common/TableHeader'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { OpenInNew } from '@mui/icons-material'
import noAssignmentImg from '../../../assets/noassignment.svg'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import StudyMaterialViewer from './StudyMaterialViewer'

const columns =
    [
        { id: 2, name: "Study Material", minWidth: "120px", padding: "10px 20px 10px 40px" },
        { id: 3, name: 'Faculty name', minWidth: "150px", align: "left", padding: "10px 20px" },
        { id: 6, name: 'DEPARTMENT', padding: "10px 20px" },
        { id: 4, name: "Subject", padding: "10px 20px" },
        { id: 5, align: "center", name: "Created on", minWidth: "150px", padding: "10px 20px" },
        { id: 7, align: "center", name: "Attachments", padding: "10px 20px" },
        { id: 8, align: "right", name: "Actions", padding: "10px 40px 10px 20px" },
    ]


const AuditStudyMaterialsList = () => {


    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", month: "", selectedDepartment: "all", selectedInstitute: "all", view: "tabular", sortByName: "1", sortBySubmissionsCount: "1", sortByAssignmentCount: "1" } )
    const searchText = searchParams.get( 'searchText' ) || ""
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"
    const selectedDepartment = searchParams.get( 'selectedDepartment' ) || "all"

    const [error, setError] = useState( null )
    const [studyMaterials, setStudyMaterials] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [selectedStudyMaterial, setSelectedStudyMaterial] = useState( null )

    const { table, palette } = useTheme()

    const navigate = useNavigate()


    const filterFunction = item => {
        const searchValue = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        if ( selectedDepartment !== "all" )
            return item?.department_id === selectedDepartment && ( item?.department_name?.match( searchValue ) || item.study_material_created_by?.match( searchValue ) || item.empcode?.match( searchValue ) )
        else {
            return item?.department_name?.match( searchValue ) || item.study_material_created_by?.match( searchValue ) || item.empcode?.match( searchValue )
        }
    }

    const removeAppliedFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', '' )
            prev.set( 'selectedInstitute', "all" )
            prev.set( 'selectedDepartment', "all" )
            return prev
        } )
    }

    const handleDialogClose = () => {
        setSelectedStudyMaterial( null )
    }


    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data } } = await api.audit.getStudyMaterialsForAuditing( { institute: selectedInstitute !== "all" ? selectedInstitute : "" } )
            setStudyMaterials( data )
            setError( null )
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.message?.data?.message || "Something went wrong while fetching studyMaterials." } )
        } finally {
            setLoading( false )
        }
    }, [selectedInstitute] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>

            {selectedStudyMaterial && <StudyMaterialViewer studyMaterial={selectedStudyMaterial} handleClose={handleDialogClose} openState={Boolean( selectedStudyMaterial )} />}

            <Box padding="20px" display="flex" gap="20px" justifyContent="space-between" >
                <Typography color="primaryDark.main" fontSize="16px" variant='h6'>Study materials</Typography>
                <Button size="small" onClick={() => navigate( "/audit/study-materials" )} variant="contained" disableElevation sx={{ height: "33.5px", textTransform: "capitalize", background: "#FFE0E0", border: `1px solid #FF6C8F`, color: "#FF736F", '&:hover': { background: "#FFE0E0", color: "#FF736F" } }} >View Stats</Button>
            </Box >

            {!loading && studyMaterials.filter( filterFunction ).length > 0 && <Box paddingBottom="20px" overflow="hidden" borderRadius="10px" bgcolor={palette.contentBg} margin="0 20px">
                <TableContainer sx={{ overflow: "auto", maxHeight: "500px" }} className='custom-scrollbar' >
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {studyMaterials.filter( filterFunction ).map( ( row, index ) => {
                                return <TableRow className='table-row' key={row.study_material_id}>
                                    <TableCell sx={{ padding: columns[0].padding || "10px 20px", color: table.color, textTransform: "capitalize", fontSize: "12px" }}><strong>{row.study_material_name}</strong></TableCell>
                                    <TableCell align={columns[1].align || "left"} sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", textTransform: "capitalize", color: table.color }}><Typography maxWidth="100px" fontSize="12px" sx={{ '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row.empcode}`} className='default-link' >{row.study_material_created_by?.toLowerCase()}</Link></strong></Typography></TableCell>
                                    <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", textTransform: "capitalize", color: table.color, }}><strong>{row?.institute} - {row?.department_name?.toLowerCase()}</strong></TableCell>
                                    <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color }}><strong>{row?.subject_name || "Not available"}</strong></TableCell>
                                    <TableCell align={columns[4].align || "left"} sx={{ fontSize: "12px", padding: columns[4].padding || "10px 20px", color: table.color }}><strong>{showDateInIndianDateFormat( row.study_material_published_date )}</strong></TableCell>
                                    <TableCell align={columns[5].align || "left"} sx={{ fontSize: "12px", padding: columns[5].padding || "10px 20px", color: table.color }}><strong>{row.attachments_count}</strong></TableCell>
                                    <TableCell align={columns[6].align || "left"} sx={{ fontSize: "12px", padding: columns[6].padding || "10px 20px", color: table.color }}>
                                        <Box display="flex" justifyContent="flex-end" gap="10px" alignItems="center">
                                            <Tooltip title="Open details">
                                                <IconButton onClick={() => setSelectedStudyMaterial( row )} color="primary" size="small"><OpenInNew fontSize='small' /></IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {!loading && studyMaterials.filter( filterFunction ).length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={noAssignmentImg} height="120px" alt="No assignmets to show" />
                <Typography textAlign="center" variant='subtitle2'>No study materials were found for applied filters!</Typography>
                {studyMaterials.length > 0 && <Button onClick={removeAppliedFilters} sx={{ textTransform: "capitalize" }} color="secondary" variant="contained" disableElevation>Remove filters</Button>}
            </Box>}
            {error && <TryAgainMessageBlock getData={getData} err={error.message} code={error.code} loading={loading} />}
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading study materials please wait...." />}
        </Card>
    )
}

export default AuditStudyMaterialsList
