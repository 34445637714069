import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useOrganizationStore } from '../../store/organization.store'
import { Icons } from '../../utils/utilities'

const InstituteSelect = ( { disabled, handleInstituteChange, selectedInstitute } ) => {

    const [instituteSelectOpen, setInstituteSelectOpen] = useState( false )

    const instituteSelectRef = useRef()

    const OrganizationStore = useOrganizationStore()

    const handleSelect = val => {
        console.log( "SELECTED FROM SELECT: ", val )

        setInstituteSelectOpen( false )
        handleInstituteChange( val )
    }

    return (
        <Box>
            <Button disabled={disabled} onClick={() => setInstituteSelectOpen( true )} ref={instituteSelectRef} endIcon={Icons.default.KeyboardArrowDownIcon} variant='contained' disableElevation sx={{ height: "40px", '&:disabled': { pointerEvents: "all", cursor: disabled && "not-allowed !important", }, background: `linear-gradient(125.39deg, #139CFF -32.54%, #C54097 110.4%)`, opacity: disabled ? 0.6 : 1 }} >
                <Typography variant='subtitle2' color={disabled ? "lightgray" : "white"}>{selectedInstitute === "all" ? "Institute" : selectedInstitute}</Typography>
            </Button>
            <Menu anchorEl={instituteSelectRef.current} open={instituteSelectOpen} onClose={() => setInstituteSelectOpen( false )}>
                <MenuItem onClick={() => handleSelect( "all" )} dense sx={{ fontSize: "12px" }} value="all">All</MenuItem>
                {
                    OrganizationStore.getOrganization.map( ( obj, i ) => {
                        return <MenuItem onClick={() => handleSelect( obj.institute_name_short )} sx={{ fontSize: "12px", textTransform: "capitalize" }} value={obj.institute_name_short} key={'key-' + i}> <strong>{obj.institute_name_short}</strong> - {obj.institute_name?.toLowerCase()}  </MenuItem>
                    } )
                }
            </Menu>
        </Box>
    )
}

export default InstituteSelect
