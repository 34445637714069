import { Box, Button, Card, CircularProgress, Collapse, Dialog, IconButton, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { flatColorsAndBackgrounds, Icons } from '../../utils/utilities'
import { CampaignOutlined, Check, Close, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useSubjectStore } from '../../store/subject.store'
import { parse as markdownParse } from 'marked'
import limitsReachedImg from '../../assets/limits-reached.svg'
import api from '../../service/api'
import { toast } from 'react-toastify'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import noDetailsImg from '../../assets/no-details.svg'
import DOMPurify from 'dompurify'
import { convert } from 'html-to-text'
import DarkTooltip from '../common/DarkTooltip'
import DataLoadingDots from '../common/DataLoadingDots'
import AiMentorChatTypeWriter from '../ai-mentor/AiMentorChatTypeWriter'

const ChapterBox = ( { chapter, moduleIndex, updateData, chapterIndex, subject, saveChanges } ) => {

    const { border, shadows, palette } = useTheme()

    const [learnTopic, setLearnTopic] = useState( null )
    const [addTopicMode, setAddTopicMode] = useState( false )
    const [nameEditMode, setNameEditMode] = useState( false )
    const [limitsReached, setLimitsReached] = useState( false )
    const [readOutResponse, setReadOutResponse] = useState( false )
    const [fetchingResponse, setFetchingResponse] = useState( false )
    const [aiResponseTagAndContent, setAiResponseTagAndContent] = useState( [] )

    const chapterNameEditFieldRef = useRef()
    const aiResponseContentContainerRef = useRef()

    const fetchAnswerFromAI = async ( chapter, topic, readOut = false ) => {
        setFetchingResponse( true )
        try {
            setLimitsReached( false )
            setReadOutResponse( readOut )
            setLearnTopic( topic )
            const { data: { data } } = await api.aimentor.askAiMentor( { subject: subject.subject_name, topic: chapter, subtopic: topic } )
            let cleanedResponse = data.response
            if ( data.response?.match( /^```markdown\n?/ ) ) {
                cleanedResponse = data.response?.replace( /^```markdown\n?/, "" )?.replace( /(```[\n\s]*)$/, "" )
            }
            const parsedMarkdown = DOMPurify.sanitize( markdownParse( cleanedResponse ) )
            if ( readOut ) {
                const utterance = new SpeechSynthesisUtterance( convert( parsedMarkdown )?.replace( /[*#]/g, "" ) )
                // Select a voice
                const voices = speechSynthesis.getVoices()
                utterance.voice = voices[0]
                speechSynthesis.speak( utterance )
            }
            let parts = parsedMarkdown.split( /(<[^>]+>)/ )
            parts = parts.filter( p => Boolean( p ) )

            const tagContentPairs = []
            for ( let i = 0; i < parts.length; i++ ) {
                const tag = parts[i].match( /<[^/][^>]*>/ )
                if ( parts[i].match( "<pre>" ) && parts[i + 1]?.match( /\sclass="language-[\w-]+"\s?/ ) ) {
                    const language = parts[i + 1]?.match( /\sclass="language-[\w-]+"\s?/ )[0]?.split( "-" ).pop()
                    const tag = `<div class="ai-response-language">${language?.replace( /["']/g, "" )}</div>`

                    tagContentPairs.push( {
                        content: tag,
                        type: 'tag'
                    } )
                }



                if ( tag ) {
                    tagContentPairs.push( {
                        content: parts[i],
                        type: 'tag'
                    } )
                } else {
                    tagContentPairs.push( {
                        content: parts[i],
                        type: 'text'
                    } )
                }
            }

            setAiResponseTagAndContent( tagContentPairs )

        } catch ( err ) {
            console.log( err )
            if ( err?.response?.data?.limitsReached ) {
                setLimitsReached( true )
            } else
                toast( err?.response?.data?.message || "AI Mentor couldn't get you an answer! please try again later" )
        } finally {
            setFetchingResponse( false )
        }
    }

    const handleAiDialogClose = () => {
        setLearnTopic( null )
        setAiResponseTagAndContent( [] )
        speechSynthesis.cancel()
    }

    const handleInputOnNewTopicsField = ( e, chapterIndex ) => {
        if ( e.key === 'Enter' || e.key === 'Tab' ) {
            const value = e.target.value
            if ( value && value.trim() !== '' ) {
                const newTopics = value.split( "," ).map( item => item.trim() ).filter( item => item.trim() !== '' )
                updateData( 'addtopic', moduleIndex, chapterIndex, newTopics )
                e.target.value = ''
                setAddTopicMode( false )
                saveChanges()
            }
        }

    }

    const handleChapterEditInputKeyUp = e => {
        if ( e.key === "Enter" ) {
            updateChapterName()
        }
    }

    const updateChapterName = () => {
        const { value } = chapterNameEditFieldRef.current
        if ( value && value?.trim() ) {
            updateData( 'updatechaptername', moduleIndex, chapterIndex, value )
        }
        setNameEditMode( false )
        saveChanges()
    }

    const removeTopic = ( index ) => {
        const newTopicsList = [...chapter.topics]
        newTopicsList.splice( index, 1 )
        updateData( 'removetopic', moduleIndex, chapterIndex, newTopicsList )
        saveChanges()
    }

    const deleteChapter = () => {
        updateData( 'deletechapter', moduleIndex, chapterIndex, {} )
        saveChanges()
    }

    return (
        <Box>

            <Dialog onClose={handleAiDialogClose} sx={{ background: "rgba(0,0,0,0.7)", display: "flex", flexDirection: "column" }} PaperProps={{ sx: { height: "85%", width: "90%", maxWidth: "100%" } }} open={Boolean( learnTopic )}>
                <Box display="flex" gap="20px" justifyContent="space-between" alignItems="center" padding="10px 20px" borderBottom={border[1]}>
                    <Typography variant='h6' fontSize="18px">
                        <em>Explain {learnTopic}</em>
                    </Typography>
                    <IconButton onClick={handleAiDialogClose}>
                        {Icons.default.CloseIcon}
                    </IconButton>
                </Box>
                <Box overflow="auto" ref={aiResponseContentContainerRef} flexGrow={1} display="flex" flexDirection="column" gap="10px" padding="20px">
                    {fetchingResponse ? <Typography fontWeight="500" variant='subtitle2' padding="10px" borderRadius="5px">
                        <DataLoadingDots spaceBetween={5} dotSize={7} waitingMessage="Working on your question..." color='grey' />
                    </Typography> : limitsReached ?
                        <Box flexGrow={1} display="flex" gap="20px" alignItems="center" flexDirection="column" padding="20px" justifyContent="center">
                            <img alt='daily-limits-reached' src={limitsReachedImg} width="200px" />
                            <Typography variant='subtitle2'>You reached the limit for the day! You can make upto 100 requests per day.</Typography>
                            <Button variant='contained' color='secondary' sx={{ textTransform: "capitalize" }} disableElevation onClick={() => setLearnTopic( null )} >Okay</Button>
                        </Box>
                        : <AiMentorChatTypeWriter containerRef={aiResponseContentContainerRef.current} tagContentPairs={aiResponseTagAndContent} speed={readOutResponse ? 60 : 10} text={''} />}
                </Box>
            </Dialog>

            <Box display="flex" gap="10px" marginBottom="10px" alignItems="center" flexWrap="wrap">
                {!nameEditMode && <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center">
                    <Typography variant='h6' fontSize='18px' color="primaryDark.main">{chapter.name} </Typography>
                </Box>}
                {nameEditMode && <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center"  >
                    <TextField inputRef={chapterNameEditFieldRef} autoFocus onFocus={e => e.target.select()} onKeyUp={handleChapterEditInputKeyUp} defaultValue={chapter.name} size="small" />
                </Box>}
                <IconButton size="small" onClick={() => setNameEditMode( !nameEditMode )} >
                    {nameEditMode ? <Close sx={{ fontSize: "18px" }} /> : <EditOutlined sx={{ fontSize: "18px" }} fontSize='small' />}
                </IconButton>
                {nameEditMode && <IconButton color="success" size="small" onClick={updateChapterName} >
                    <Check sx={{ fontSize: "18px" }} />
                </IconButton>}
                <IconButton color="error" size="small" onClick={deleteChapter} >
                    <DeleteOutline sx={{ fontSize: "18px" }} fontSize='small' />
                </IconButton>
            </Box>
            <Box display="flex" gap="10px" flexWrap="wrap">
                {chapter.topics.map( ( topic, index ) => {
                    const { bg, color } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                    return (
                        <Box sx={{ cursor: "pointer", '&:hover': { borderColor: color, background: bg, color, boxShadow: shadows[1], '& .show-on-hover': { display: "inline-block" } } }} key={index} display="flex" alignItems="center" borderRadius="10px" border={border[1]}>
                            <DarkTooltip title={<Typography color="inherit" fontSize="12px" className='m-0'>Ask AI Tutor: <strong style={{ color: palette.primary.dark }}>Explain {topic}</strong></Typography>} >
                                <Typography onClick={() => fetchAnswerFromAI( chapter.name, topic )} padding="5px 10px" variant='subtitle2'>{topic}</Typography>
                            </DarkTooltip>
                            <DarkTooltip title={<Typography color="inherit" fontSize="12px" className='m-0'>Ask AI Tutor: <strong style={{ color: palette.primary.dark }}>Explain {topic} and read out loud</strong></Typography>}>
                                <IconButton onClick={() => fetchAnswerFromAI( chapter.name, topic, true )} sx={{ color: "gray", display: "none", width: "30px", height: "30px" }} className='show-on-hover' size='small' > <CampaignOutlined fontSize='small' /> </IconButton>
                            </DarkTooltip>
                            <IconButton onClick={() => removeTopic( index )} size="small">
                                <Close fontSize='small' />
                            </IconButton>
                        </Box>
                    )
                } )}
                {!addTopicMode && <Button onClick={() => setAddTopicMode( true )} startIcon={Icons.default.AddIcon} color="secondary">
                    <Typography sx={{ textTransform: "capitalize" }} variant="subtitle2" fontSize="12px" color="secondary">Add topic</Typography>
                </Button>}
                {addTopicMode && <Card sx={{ display: "flex", alignItems: "center", border: border[1] }}>
                    <Box padding="0 10px" display="flex" gap="10px" alignItems="center">
                        <TextField onKeyDown={e => handleInputOnNewTopicsField( e, chapterIndex )} autoFocus onFocus={e => e.target.select()} defaultValue="New topic" placeholder='New topic...' size="small" variant='standard' InputProps={{ disableUnderline: true, sx: { fontSize: "14px" } }} />
                        <IconButton size="small" onClick={() => setAddTopicMode( false )}>
                            {Icons.default.CloseIcon}
                        </IconButton>
                    </Box>
                </Card>}
            </Box>
        </Box>
    )
}

const ModuleBox = ( { module, moduleIndex, updateData, subject, saveChanges } ) => {

    const [addChapterMode, setAddChapterMode] = useState( false )
    const [collapseState, setCollapseState] = useState( false )
    const [nameEditMode, setNameEditMode] = useState( false )

    const { border, shadows } = useTheme()

    const moduleNameEditFieldRef = useRef()

    const handleKeyDownOnNewChapterField = ( e ) => {
        let chaptersList = []
        if ( e.key === 'Enter' || e.key === 'Tab' ) {
            const value = e.target.value
            if ( value && value.trim() !== '' ) {
                const chapters = value.split( ";" )
                for ( let i = 0; i < chapters.length; i++ ) {
                    const data = {}
                    const chapter = chapters[i]
                    const [name, topics = ''] = chapter.split( ":" )
                    data.name = name?.trim()
                    data.topics = []
                    if ( topics ) {
                        data.topics = topics.split( "," ).map( item => item.trim() ).filter( item => item.trim() !== '' )
                    }
                    if ( data.name )
                        chaptersList.push( data )
                }
                e.target.value = ''
                updateData( 'addchapter', moduleIndex, null, chaptersList )
                saveChanges()
                setAddChapterMode( false )
            }
        }
    }

    const updateModuleName = e => {
        const { value } = moduleNameEditFieldRef.current
        if ( value && value?.trim() ) {
            updateData( 'updatemodulename', moduleIndex, null, value )
        }
        setNameEditMode( false )
        saveChanges()
    }

    const deleteModule = () => {
        updateData( 'deletemodule', moduleIndex, null, {} )
    }

    return (
        <Box boxShadow={shadows[1]} borderRadius="5px">
            <Box padding="10px 20px" alignItems="center" display="flex" gap="10px">
                {!nameEditMode && <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center" onClick={() => setCollapseState( !collapseState )} >
                    {collapseState ? Icons.default.DropUp : Icons.default.DropDown}
                    <Typography variant='h6' fontSize='16px' color="primaryDark.main">{module.name}</Typography>
                </Box>}
                {nameEditMode && <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center"  >
                    {collapseState ? Icons.default.DropUp : Icons.default.DropDown}
                    <TextField inputRef={moduleNameEditFieldRef} onKeyUp={e => {
                        if ( e.key === 'Enter' )
                            updateModuleName()
                    }} autoFocus onFocus={e => e.target.select()} defaultValue={module.name} size="small" />
                </Box>}
                <IconButton size="small" onClick={() => setNameEditMode( !nameEditMode )} >
                    {nameEditMode ? <Close sx={{ fontSize: "18px" }} /> : <EditOutlined sx={{ fontSize: "18px" }} fontSize='small' />}
                </IconButton>
                {nameEditMode && <IconButton color="success" size="small" onClick={updateModuleName} >
                    <Check sx={{ fontSize: "18px" }} />
                </IconButton>}
                <IconButton color="error" size="small" onClick={deleteModule} >
                    <DeleteOutline sx={{ fontSize: "18px" }} fontSize='small' />
                </IconButton>
            </Box>
            <Collapse in={collapseState}>
                <Box display="flex" borderTop={border[1]} flexDirection="column" gap="20px" padding="20px">
                    {module.chapters.map( ( chapter, index ) => (
                        <ChapterBox saveChanges={saveChanges} key={index} subject={subject} chapter={chapter} chapterIndex={index} updateData={updateData} moduleIndex={moduleIndex} />
                    ) )}
                    {addChapterMode && <Box border={border[1]} bgcolor="#d3d3d333" borderRadius="5px" padding="5px 10px" display="flex" gap="10px" alignItems="center">
                        <TextField onKeyDown={handleKeyDownOnNewChapterField} autoFocus sx={{ '& .MuiInputBase-root': { background: "none" } }} fullWidth onFocus={e => e.target.select()} placeholder='Chapter-1:topic-1,topic-2,topic-3;Chapter-2:topic-4,topic-5...' variant='standard' InputProps={{ disableUnderline: true, sx: { fontSize: "16px", fontWeight: "500" } }} />
                        <IconButton size="small" onClick={() => setAddChapterMode( false )}>
                            {Icons.default.CloseIcon}
                        </IconButton>
                    </Box>}
                    {!addChapterMode && <Box bgcolor="#d3d3d333" onClick={() => setAddChapterMode( true )} width="fit-content" padding="10px" borderRadius="5px" sx={{ cursor: 'pointer' }} color="GrayText" display="flex" gap="10px" alignItems="center">
                        {Icons.default.AddIcon}
                        <Typography variant='subtitle2' >Add new chapter</Typography>
                    </Box>}
                </Box>
            </Collapse >
        </Box >
    )
}

const AiMentorFaculty = () => {

    const [loading, setLoading] = useState( true )
    const [isSaving, setIsSaving] = useState( false )
    const [addModuleMode, setAddModuleMode] = useState( false )
    const [areChangesMade, setAreChangesMade] = useState( false )
    const [subjectSyllabus, setSubjectSyllabus] = useState( [] )

    const { palette, border } = useTheme()
    const SubjectStore = useSubjectStore()

    const updateData = ( type, moduleIndex = null, chapterIndex = null, data ) => {
        const newData = [...subjectSyllabus]
        if ( type === 'addmodule' ) {
            newData.push( ...data )
        } else if ( type === 'addchapter' ) {
            newData[moduleIndex]['chapters'].push( ...data )
        } else if ( type === 'addtopic' ) {
            newData[moduleIndex]['chapters'][chapterIndex]['topics'].push( ...data )
        } else if ( type === 'removetopic' ) {
            newData[moduleIndex]['chapters'][chapterIndex]['topics'] = data
        } else if ( type === 'updatemodulename' ) {
            newData[moduleIndex]['name'] = data
        } else if ( type === 'deletemodule' ) {
            newData.splice( moduleIndex, 1 )
        } else if ( type === 'deletechapter' ) {
            newData[moduleIndex]['chapters'].splice( chapterIndex, 1 )
        } else if ( type === 'updatechaptername' ) {
            newData[moduleIndex]['chapters'][chapterIndex]['name'] = data
        }
        setSubjectSyllabus( newData )
        setAreChangesMade( true )
    }

    const handleKeyDownOnNewModuleField = e => {
        if ( e.key === 'Enter' || e.key === 'Tab' ) {
            const value = e.target.value?.trim()
            if ( value ) {
                let modules = value.split( '&' )
                const modulesList = []
                for ( let i = 0; i < modules.length; i++ ) {
                    const data = {}
                    const module = modules[i]
                    if ( module.includes( "{" ) && module.includes( "}" ) ) {
                        const openBrace = module.indexOf( "{" )
                        data.name = module.substring( 0, openBrace )?.trim()
                        data.chapters = []
                        const chaptersList = module.substring( openBrace + 1, module.length - 1 )
                        const chapters = chaptersList.split( ";" )
                        for ( let i = 0; i < chapters.length; i++ ) {
                            const chapterdata = {}
                            const chapter = chapters[i]
                            const [name, topics = ''] = chapter.split( ":" )
                            chapterdata.name = name?.trim()
                            if ( topics ) {
                                chapterdata.topics = topics.split( "," ).map( item => item.trim() ).filter( item => item.trim() !== '' )
                            }
                            if ( chapterdata.name )
                                data.chapters.push( chapterdata )
                        }
                    } else {
                        data.name = module
                        data.chapters = []
                    }
                    modulesList.push( data )
                    e.target.value = ''
                    setAddModuleMode( false )
                }
                updateData( 'addmodule', null, null, modulesList )
            }
        }
    }

    const saveChanges = async () => {
        setIsSaving( true )
        try {
            const payload = {}
            payload.subject_syllabus = subjectSyllabus
            payload.subject_details = {
                subject_code: SubjectStore.getSubject.subject_code,
                subject_id: SubjectStore.getSubject.subject_id,
                subject_name: SubjectStore.getSubject.subject_name,
                subject_name_short: SubjectStore.getSubject.subject_name_short,
                sem: SubjectStore.getSubject.sem,
                year: SubjectStore.getSubject.year,
            }
            await api.aimentor.saveSubjectSyllabus( SubjectStore.getSubject.subject_code, payload )
            setAreChangesMade( false )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while updating the subject syllabus" )
        } finally {
            setIsSaving( false )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const { data } = await api.aimentor.getSubjectSyllabus( SubjectStore.getSubject.subject_code )
                setSubjectSyllabus( data.data?.modules || [] )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fetching subject syllabus data." )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [SubjectStore] )

    return (
        <Box display="flex" overflow="auto" flexDirection="column" flexGrow={1}>
            <Box bgcolor={palette.greyedOut} padding="10px 20px" display="flex" gap="10px" alignItems="center" justifyContent="space-between">
                <Typography variant='h6'>Subject Syllabus</Typography>
                {areChangesMade && <Button disabled={isSaving} onClick={saveChanges} color="success" size="small" disableElevation variant="contained" sx={{ textTransform: "capitalize" }} startIcon={isSaving ? <CircularProgress size={14} /> : Icons.default.CheckIcon} >Save changes</Button>}
            </Box>
            {!loading && <Box overflow="auto" display="flex" flexDirection="column" gap="10px" padding="10px" flexGrow={1}>
                {subjectSyllabus.map( ( module, moduleIndex ) => {
                    return (
                        <ModuleBox saveChanges={saveChanges} subject={SubjectStore.getSubject} key={moduleIndex} updateData={updateData} moduleIndex={moduleIndex} module={module} />
                    )
                } )}
                {subjectSyllabus.length === 0 && <Box display="flex" flexDirection="column" padding="20px" borderRadius="5px" bgcolor={palette.contentBg} margin="10px" gap="20px" alignItems="center">
                    <img height="120px" src={noDetailsImg} alt="no-details" />
                    <Typography variant="subtitle2" padding="20px" paddingBottom="0" >No modules added yet!</Typography>
                </Box>}
                {addModuleMode && <Box border={border[1]} margin="20px" bgcolor="#d3d3d333" borderRadius="5px" padding="5px 10px" display="flex" gap="10px" alignItems="center">
                    <TextField onKeyDown={handleKeyDownOnNewModuleField} sx={{ '& .MuiInputBase-root': { background: "none" } }} fullWidth onFocus={e => e.target.select()} placeholder='Eg: Module 1{Chapter-1:topic-1;Chapter-2:topic-4,topic-5}&Module 2{Chapter-1:topic-1;Chapter-2:topic-4}...' variant='standard' InputProps={{ disableUnderline: true, sx: { fontSize: "16px", fontWeight: "500" } }} />
                    <IconButton size="small" onClick={() => setAddModuleMode( false )}>
                        {Icons.default.CloseIcon}
                    </IconButton>
                </Box>}
                {!addModuleMode && <Box margin="20px" bgcolor="#d3d3d333" onClick={() => setAddModuleMode( true )} width="fit-content" padding="10px" borderRadius="5px" sx={{ cursor: 'pointer' }} color="GrayText" display="flex" gap="10px" alignItems="center">
                    {Icons.default.AddIcon}
                    <Typography variant='subtitle2' >Add module</Typography>
                </Box>}
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading data..." />}
        </Box>
    )
}

export default AiMentorFaculty
