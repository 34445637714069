import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import React from 'react'

// Layouts
import PresenterLayout from '../presenter/layout/PresenterLayout'
import RequireAuditAccess from '../audit/RequireAuditAccess'
import AdminLayout from '../admin/layout/AdminLayout'
import UserLayout from '../user/layout/UserLayout'
import RequireAuth from './RequireAuth'

// Exams
import StudentParticipationDetails from '../../pages/exam/student/StudentParticipationDetails'
import AllParticipations from '../../pages/exam/faculty/AllExamsParticipations'
import ExamParticipation from '../../pages/exam/faculty/ExamParticipation'
import EditQuestions from '../../pages/exam/faculty/EditQuestions'
import AddQuestions from '../../pages/exam/faculty/AddQuestions'
import ExamAttempt from '../../pages/exam/student/ExamAttempt'
import EditExam from '../../pages/exam/faculty/EditExam'
import Evaluate from '../../pages/exam/faculty/Evaluate'
import AddExam from '../../pages/exam/faculty/AddExam'
import Scores from '../../pages/exam/student/Scores'
import Exams from '../../pages/exam/Exams'
import Exam from '../../pages/exam/Exam'

// Study Material
import AddMaterial from '../../pages/study-material/AddMaterial'
import Materials from '../../pages/study-material/Materials'
import Material from '../../pages/study-material/Material'
import FacultyDashboard from '../../pages/faculty/Dashboard'
import Notfound from '../../pages/Notfound'

// Video Tutorials
import StudentOnlineCourses from '../../pages/student/online-course-pages/OnlineCourses'
import AddOnlineCourse from '../../pages/admin/online-course-pages/AddOnlineCourse'
import OnlineCourses from '../../pages/admin/online-course-pages/OnlineCourses'

// Admin
import DepartmentAcademicRuleChecklist from '../../pages/admin/academic-program/DeparmentAcademicRuleCheklist'
import AcademicProgressAuditors from '../../pages/admin/academic-program/AcademicProgressAuditors'
import AdminAcademicProgressAudit from '../../pages/admin/academic-program/AcademicProgressAudit'
import InstituteAcademicRules from '../../pages/admin/academic-program/InstituteAcademicRules'
import ResumeTemplates from '../../pages/admin/resume-builder/ResumeTemplates'
import ResumeEntities from '../../pages/admin/resume-builder/ResumeEntities'
import ResumeTemplate from '../../pages/admin/resume-builder/ResumeTemplate'
import FeedbackSettings from '../../pages/admin/feedback/FeedbackSettings'
import ResumeForms from '../../pages/admin/resume-builder/ResumeForms'
import ResumeForm from '../../pages/admin/resume-builder/ResumeForm'
import AttendanceAudit from '../../pages/admin/AttendanceAudit'
import ManageAccounts from '../../pages/admin/ManageAccounts'
import ControlPanel from '../../pages/admin/ControlPanel'
import ApiSettings from '../../pages/admin/ApiSettings'
import Institution from '../../pages/admin/Institution'
import Recording from '../../pages/admin/Recordings'
import EmbeddBbb from '../../pages/common/EmbeddBbb'
import Analytics from '../../pages/admin/Analytics'
import DebugUser from '../../pages/admin/DebugUser'
import AdminSignin from '../../pages/admin/Signin'
import Password from '../../pages/admin/Password'
import LiveClass from '../../pages/common/Audit'
import Home from '../../pages/admin/Home'

// Faculty and Student
import AcademicProgressAudit from '../../pages/faculty/proctoring/audit/AcademicProgressAudit'
import LearnOnlineCourse from '../../pages/student/online-course-pages/LearnOnlineCourse'
import StudentOnlineCourse from '../../pages/student/online-course-pages/OnlineCourse'
import EnrolledCourses from '../../pages/student/online-course-pages/EnrolledCourses'
import ResumeBuilderForm from '../../pages/student/resume-builder/ResumeBuilderForm'
import OnlineCourse from '../../pages/admin/online-course-pages/OnlineCourse'
import ResumePreview from '../../pages/student/resume-builder/ResumePreview'
import QuestionBanks from '../../pages/faculty/questionbank/QuestionBanks'
import EvaluateAssignment from '../../pages/faculty/EvaluateAssignment'
import SubjectOnlineCourses from '../subjects/SubjectOnlineCourses'
import SessionFeedback from '../../pages/student/SessionFeedback'
import SubjectMaterials from '../subjects/SubjectStudyMaterials'
import SubjectDiscussions from '../subjects/SubjectDiscussions'
import SubjectAssignments from '../subjects/SubjectAssignments'
import PresenterRecording from '../../pages/faculty/Recording'
import Proctees from '../../pages/faculty/proctoring/Proctees'
import Proctee from '../../pages/faculty/proctoring/Proctee'
import StudentDashboard from '../../pages/student/Dashboard'
import UserAssignment from '../../pages/student/Assignment'
import UserAttendence from '../../pages/student/Attendence'
import SubjectDashboard from '../subjects/SubjectDashboard'
import MyDiscussions from '../../pages/forum/MyDiscussions'
import Feedback from '../../pages/admin/feedback/Feedback'
import Leaderboard from '../../pages/student/Leaderboard'
import Discussions from '../../pages/forum/Discussions'
import Assignment from '../../pages/faculty/Assignment'
import SubjectClasses from '../subjects/SubjectClasses'
import Attendence from '../../pages/faculty/Attendence'
import Academics from '../../pages/student/Academics'
import Discussion from '../../pages/forum/Discussion'
import Quizzes from '../../pages/faculty/quiz/Quizzes'
import SubjectExams from '../subjects/SubjectExams'
import PageNotFound from '../../pages/PageNotFound'
import NetworkError from '../../pages/NetworkError'
import ExamNotfound from '../../pages/ExamNotFound'
import Subjects from '../../pages/common/Subjects'
import Subject from '../../pages/common/Subject'
import Quiz from '../../pages/faculty/quiz/Quiz'
import AliveLogin from '../../pages/AliveLogin'
import FAQs from '../../pages/FAQs'
import QuestionBank from '../../pages/faculty/questionbank/QuestionBank'
import StudentQuiz from '../../pages/student/quiz/StudentQuiz'
import QuizAttempt from '../../pages/student/quiz/QuizAttempt'
import SubjectClassSessions from '../../pages/subject/SubjectClassSessions'
import ClassSession from '../../pages/subject/ClassSession'
// import AiTutor from '../../pages/student/ai-tutor/AiTutor'
import StreamSessionEnded from '../../pages/common/StreamSessionEnded'
import AuditDashboard from '../../pages/audit/AuditDashboard'
import AuditingLayout from '../audit/AuditingLayout'
import AuditAssignments from '../../pages/audit/AuditAssignments'
import FacultyAudit from '../../pages/audit/FacultyAudit'
import AssignmentsStats from '../audit/assignments/AssignmentsStats'
import AssignmentList from '../audit/assignments/AssignmentList'
import AiMentorFaculty from '../subjects/AiMentorFaculty'
import AiMentorStudent from '../subjects/AiMentorStudent'
import AuditExams from '../../pages/audit/AuditExams'
import ExamsStats from '../audit/exams/ExamsStats'
import AuditExamsList from '../audit/exams/AuditExamsList'
import AuditStudyMaterials from '../../pages/audit/AuditStudyMaterials'
import StudyMaterialsStats from '../audit/study-materials/StudyMaterialsStats'
import AuditQuizzes from '../../pages/audit/AuditQuizzes'
import QuizzesStats from '../audit/quizzes/QuizzesStats'
import AuditQuizzesList from '../audit/quizzes/AuditQuizzesList'
import AuditQuestionBanks from '../../pages/audit/AuditQuestionBanks'
import QuestionBanksStats from '../audit/questionbanks/QuestionBanksStats'
import AuditQuestionBanksList from '../audit/questionbanks/AuditQuestionBanksList'
import AuditStudyMaterialsList from '../audit/study-materials/AuditStudyMaterialsList'
import AuditDiscussions from '../../pages/audit/AuditDiscussions'
import DiscussionsStats from '../audit/discussions/DiscussionsStats'
import AuditDiscussionsList from '../audit/discussions/AuditDiscussionsList'
import TemporaryBatches from '../../pages/admin/TemporaryBatches'
import AdmissionExamsRequireAuth from '../admission-exam/layout/AdmissionExamsRequireAuth'
import AdmissionExamLayout from '../admission-exam/layout/AdmissionExamLayout'
import ThankYouPage from '../../pages/admission-exam/ThankYouPage'
import AdmissionExamAttempt from '../../pages/admission-exam/AdmissionExamAttempt'
import AdmissionExams from '../../pages/admission-exam/AdmissionExams'
import AdmissionExam from '../../pages/admission-exam/AdmissionExam'
import AdmissionExamEvaluate from '../../pages/admission-exam/AdmissionExamEvaluate'
import AdmissionExamParticipation from '../../pages/admission-exam/ExamParticipation'
import CreateAdmissionExam from '../../pages/admission-exam/CreateAdmissionExam'
import CandidateLogin from '../../pages/admission-exam/CandidateLogin'
import AdmissionExamWorksheetUpload from '../../pages/admission-exam/AdmissionExamWorksheetUpload'
import CandidateParticipationDetails from '../../pages/admission-exam/CandidateParticipationDetails'
// import AiMentorStudent from '../subjects/AiMentorStudent'


const AppRoutes = observer( () => {

    return (
        <Router>
            <Routes>
                <Route exact path='/stream-ended' element={<StreamSessionEnded />} />
                <Route path='/admin/login' element={<AdminSignin />} />
                <Route path='/admission-exams/login' element={<CandidateLogin />} />
                <Route exact path='*' element={<PageNotFound />} />
                <Route path='/admin' element={<AdminSignin />} />
                <Route exact path='/' element={<AliveLogin />} />
                <Route path='/login' element={<AliveLogin />} />

                <Route element={<AdmissionExamsRequireAuth allowedRoles={["STUDENT"]} />}>
                    <Route element={<AdmissionExamLayout />}>
                        <Route path="/thankyou" exact element={<ThankYouPage />} />
                        <Route path="/admission-exam/:id/:pid/attempt/:attempt" exact element={<AdmissionExamAttempt />} />
                        <Route path="/admission-exam/:id/:pid/worksheet-upload" exact element={<AdmissionExamWorksheetUpload />} />
                        <Route path="/admission-exam/:id/:pid/score" exact element={<CandidateParticipationDetails />} />
                    </Route>
                </Route>

                <Route element={<RequireAuditAccess allowedRoles={['HOD', 'PRINCIPAL', 'ADMIN', 'SUBADMIN', 'is_auditor']} />}>
                    <Route element={<AuditingLayout />}>
                        <Route path='/audit' element={<AuditDashboard />} />
                        <Route path='/audit/assignments' element={<AuditAssignments />} >
                            <Route path='' element={<AssignmentsStats />} />
                            <Route path='list' element={<AssignmentList />} />
                        </Route>
                        <Route path='/audit/exams' element={<AuditExams />} >
                            <Route path='' element={<ExamsStats />} />
                            <Route path='list' element={<AuditExamsList />} />
                        </Route>
                        <Route path='/audit/study-materials' element={<AuditStudyMaterials />} >
                            <Route path='' element={<StudyMaterialsStats />} />
                            <Route path='list' element={<AuditStudyMaterialsList />} />
                        </Route>
                        <Route path='/audit/quizzes' element={<AuditQuizzes />} >
                            <Route path='' element={<QuizzesStats />} />
                            <Route path='list' element={<AuditQuizzesList />} />
                        </Route>
                        <Route path='/audit/questionbanks' element={<AuditQuestionBanks />} >
                            <Route path='' element={<QuestionBanksStats />} />
                            <Route path='list' element={<AuditQuestionBanksList />} />
                        </Route>
                        <Route path='/audit/discussions' element={<AuditDiscussions />} >
                            <Route path='' element={<DiscussionsStats />} />
                            <Route path='list' element={<AuditDiscussionsList />} />
                        </Route>
                        <Route path='/audit/faculty/:auid' element={<FacultyAudit />} />
                    </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={["ADMIN", "SUBADMIN"]} />}>
                    <Route element={<AdminLayout />} >

                        <Route path="/admin/admission-exam/" exact element={<AdmissionExams />} />
                        <Route path="/admin/admission-exam/exam/:id" exact element={<AdmissionExam />} />
                        <Route path="/admin/admission-exam/new" exact element={<CreateAdmissionExam />} />
                        <Route path="/admin/admission-exam/:id/participation" exact element={<AdmissionExamParticipation />} />
                        <Route path="/admin/admission-exam/:id/evaluate/:participation_id" exact element={<AdmissionExamEvaluate />} />

                        <Route path='/admin/online-course' element={<OnlineCourses />} />
                        <Route path='/admin/online-course/add' element={<AddOnlineCourse />} />
                        <Route path='/admin/online-course/:course_id' element={<OnlineCourse />} />

                        <Route path='/admin/resume-forms' element={<ResumeForms />} />
                        <Route path='/admin/resume-forms/:form_id' element={<ResumeForm />} />
                        <Route path='/admin/resume-entities' element={<ResumeEntities />} />
                        <Route path='/admin/resume-templates' element={<ResumeTemplates />} />
                        <Route path='/admin/resume-templates/:template_id' element={<ResumeTemplate />} />

                        <Route path='/admin/academic-rules' element={<InstituteAcademicRules />} />
                        <Route path='/admin/academics/audit' element={<AdminAcademicProgressAudit />} />
                        <Route path='/admin/academics/auditors' element={<AcademicProgressAuditors />} />
                        {/* <Route path='/admin/academic-rules/:institute_id' element={<InstituteAcademicRules />} /> */}
                        <Route path='/admin/academic-rule/:id/checklist' element={<DepartmentAcademicRuleChecklist />} />

                        <Route path='/admin/home' element={<Home />} />
                        <Route path='/admin/debug' element={<DebugUser />} />
                        <Route path='/admin/audit' element={<LiveClass />} />
                        {/* <Route path='/admin/exam' element={<ExamsReport />} /> */}
                        <Route path='/admin/stream' element={<EmbeddBbb />} />
                        <Route path='/admin/password' element={<Password />} />
                        <Route path='/admin/feedback' element={<Feedback />} />
                        <Route path='/admin/analytics' element={<Analytics />} />
                        <Route path='/admin/recordings' element={<Recording />} />
                        <Route path='/admin/institution' element={<Institution />} />
                        <Route path='/admin/api-setting' element={<ApiSettings />} />
                        <Route path='/admin/accounts' element={<ManageAccounts />} />
                        <Route path='/admin/attendance' element={<AttendanceAudit />} />
                        <Route path='/admin/control-panel' element={<ControlPanel />} />
                        {/* <Route path='/admin/discussions' element={<DiscussionsAudit />} /> */}
                        {/* <Route path='/admin/study-material' element={<StudyMaterial />} /> */}
                        <Route path='/admin/temporary-users' element={<TemporaryBatches />} />
                        <Route path='/admin/feedback-setting' element={<FeedbackSettings />} />
                        <Route path='/admin/networkerror' element={<NetworkError />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"]} />}>
                    <Route element={<PresenterLayout />}>

                        <Route path='/faculty/audit' element={<LiveClass />} />
                        <Route path='/faculty/stream' element={<EmbeddBbb />} />
                        <Route path='/faculty/home' element={<FacultyDashboard />} />
                        <Route path='/faculty/attendance' element={<Attendence />} />
                        <Route path='/faculty/assignment' element={<Assignment />} />
                        <Route path='/faculty/recordings' element={<PresenterRecording />} />
                        <Route path='/faculty/assignment/:assignment_id' element={<EvaluateAssignment />} />

                        {/* Academics */}
                        <Route path='/faculty/proctoring/proctees' element={<Proctees />} />
                        <Route path='/faculty/academics/audit' element={<AcademicProgressAudit />} />
                        <Route path='/faculty/proctoring/proctee/:student_auid' element={<Proctee />} />

                        <Route path='/faculty/material' element={<Materials />} />
                        <Route path='/faculty/material/add' element={<AddMaterial />} />
                        <Route path='/faculty/material/:material_id' element={<Material />} />

                        <Route path='/faculty/quizzes' element={<Quizzes fromComponent="page" />} />
                        <Route path='/faculty/quizzes/:id' element={<Quiz fromComponent="page" />} />
                        <Route path='/faculty/questionbank' element={<QuestionBanks fromMainPage />} />
                        <Route path='/faculty/questionbank/:bank_id' element={<QuestionBank fromMainPage />} />

                        <Route path='/faculty/exams' element={<Exams />} />
                        <Route path='/faculty/exams/:id' element={<Exam />} />
                        <Route path='/faculty/addexam' element={<AddExam />} />
                        <Route path='/faculty/exams/:id/edit' element={<EditExam />} />
                        <Route path='/faculty/participations' element={<AllParticipations />} />
                        <Route path='/faculty/exams/:id/questions' element={<EditQuestions />} />
                        <Route path='/faculty/exams/:id/addquestions' element={<AddQuestions />} />
                        <Route path='/faculty/exams/:id/participation' element={<ExamParticipation />} />
                        <Route path='/faculty/exams/:id/evaluate/:participation_id' element={<Evaluate />} />

                        <Route path='/faculty/discussions' element={<Discussions />} >
                            <Route path=':id' element={<Discussion fromComponent />} />
                        </Route>
                        <Route path='/faculty/my-discussions' element={<MyDiscussions />} >
                            <Route path=':id' element={<Discussion fromComponent />} />
                        </Route>
                        <Route path='/faculty/discussion/:id' element={<Discussion />} />

                        <Route path='/faculty/subjects' element={<Subjects />} />
                        <Route path='/faculty/subject/:subject_id' element={<Subject />} >
                            <Route path='home' element={<SubjectDashboard />} />
                            <Route path='aimentor' element={<AiMentorFaculty />} />

                            <Route path='class-sessions' element={<SubjectClassSessions />} />
                            <Route path='class-sessions/:session_id' element={<ClassSession />} />

                            <Route path='classes' element={<SubjectClasses />} />
                            <Route path='assignments' element={<SubjectAssignments />} />
                            <Route path='exams' element={<SubjectExams />} />
                            <Route path='exams/:id' element={<Exam fromSubject />} />
                            <Route path='discussions' element={<SubjectDiscussions />} >
                                <Route path=':id' element={<Discussion fromComponent />} />
                            </Route>
                            <Route path='questionbank' element={<QuestionBanks />} />
                            <Route path='questionbank/:bank_id' element={<QuestionBank />} />
                            <Route path='quiz' element={<Quizzes />} />
                            <Route path='quiz/:id' element={<Quiz />} />
                            <Route path='materials' element={<SubjectMaterials />} />
                            <Route path='materials/:material_id' element={<Material fromSubject />} />
                        </Route>

                        <Route path='/faculty/notfound' element={<Notfound />} />
                        <Route path='/faculty/examnotfound' element={<ExamNotfound />} />
                        <Route path='/faculty/networkerror' element={<NetworkError />} />
                        <Route exact path='/faculty/faq' element={<FAQs auth="faculty" />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={["STUDENT"]} />}>
                    <Route element={<UserLayout />}>

                        <Route path='/student/home' element={<StudentDashboard />} />
                        <Route path='/student/stream' element={<EmbeddBbb />} />
                        <Route path='/student/dashboard' element={<StudentDashboard />} />
                        <Route path='/student/feedback' element={<SessionFeedback />} />
                        <Route path='/student/assignment' element={<UserAssignment />} />
                        <Route path='/student/attendance' element={<UserAttendence />} />

                        <Route path='/student/subjects' element={<Subjects />} />
                        <Route path='/student/subject/:subject_id' element={<Subject />} >
                            <Route path='home' element={<SubjectDashboard />} />
                            <Route path='aimentor' element={<AiMentorStudent />} />

                            <Route path='class-sessions' element={<SubjectClassSessions />} />
                            <Route path='class-sessions/:session_id' element={<ClassSession />} />

                            <Route path='classes' element={<SubjectClasses />} />
                            <Route path='assignments' element={<SubjectAssignments />} />
                            <Route path='exams' element={<SubjectExams />} />
                            <Route path='exams/:id' element={<Exam fromSubject />} />
                            <Route path='discussions' element={<SubjectDiscussions />} >
                                <Route path=':id' element={<Discussion fromComponent />} />
                            </Route>
                            <Route path='online-courses' element={<SubjectOnlineCourses />} />
                            <Route path='materials' element={<SubjectMaterials />} />
                            <Route path='materials/:material_id' element={<Material fromSubject />} />
                        </Route>

                        <Route path='/student/my-online-course/' element={<EnrolledCourses />} />
                        <Route path='/student/online-course' element={<StudentOnlineCourses />} />
                        <Route path='/student/online-course/:course_id' element={<StudentOnlineCourse />} />
                        <Route path='/student/online-course/:course_id/learn/:progress_id' element={<LearnOnlineCourse />} />


                        <Route path='/student/academics' element={<Academics />} />

                        <Route path='/student/leaderboard' element={<Leaderboard />} />

                        <Route path='/student/material' element={<Materials />} />
                        <Route path='/student/material/:material_id' element={<Material />} />


                        <Route path='/student/exams' element={<Exams />} />
                        <Route path='/student/scores' element={<Scores />} />
                        <Route path='/student/exams/:id' element={<Exam />} />
                        <Route path='/student/notfound' element={<Notfound />} />
                        <Route path='/student/examnotfound' element={<ExamNotfound />} />
                        <Route path='/student/exams/:id/attempt/:attempt/:pid' element={<ExamAttempt />} />
                        <Route path='/student/scores/:exam_id/:participation_id' element={<StudentParticipationDetails />} />

                        <Route path='/student/quiz' element={<StudentQuiz />} />
                        <Route path='/student/quiz/:quiz_id/:instance_id/attempt/:attempt_id' element={<QuizAttempt />} />

                        <Route path='/student/resume-builder' element={<ResumeBuilderForm />} />
                        <Route path='/student/resume-builder/preview' element={<ResumePreview />} />

                        {/* <Route path='/student/ai-tutor' element={<AiTutor />} /> */}

                        <Route path='/student/discussions' element={<Discussions />} >
                            <Route path=':id' element={<Discussion fromComponent />} />
                        </Route>
                        <Route path='/student/discussion/:id' element={<Discussion />} />
                        <Route path='/student/my-discussions' element={<MyDiscussions />} >
                            <Route path=':id' element={<Discussion fromComponent />} />
                        </Route>

                        <Route path='/student/networkerror' element={<NetworkError />} />
                        <Route exact path='/student/faq' element={<FAQs auth="student" />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
} )

export default AppRoutes
