import { Avatar, Box, CircularProgress, InputLabel, Typography, useTheme } from '@mui/material'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import { Icons } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useState } from 'react'
import React from 'react'
import ProfileImageCropper from './ProfileImageCropper'
import { toast } from 'react-toastify'

const ImageInput = observer( ( { attrkey, sectionResponseJson, handleFieldChange, section, attr } ) => {

    const StudentResumeFormStore = useStudentResumeFormStore()

    const [fileSrc, setFileSrc] = useState( null )
    const [imageSrcForCropping, setImageSrcForCropping] = useState( null )
    const [loadingImage, setLoadingImage] = useState( false )
    const [isDraggingOver, setIsDraggingOver] = useState( false )

    const { palette } = useTheme()

    const getImageUrl = async () => {
        if ( ( StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] || sectionResponseJson[attrkey] ) ) {
            setLoadingImage( true )
            const key = StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] || sectionResponseJson[attrkey]
            setFileSrc( await StudentResumeFormStore.getFilePresignedUrl( key ) )
            setLoadingImage( false )
        }
    }

    const handleFileFieldChange = async ( e ) => {
        const { files } = e.target
        if ( files.length > 0 ) {
            const file = files[0]
            if ( ["jpeg", 'jpg', 'png'].includes( file.name?.split( "." )?.pop()?.toLowerCase() ) ) {
                if ( file.size <= 1 * 1024 * 1024 ) {
                    const originalFileUrl = URL.createObjectURL( files[0] )
                    setImageSrcForCropping( originalFileUrl )
                } else
                    toast( "File too large, Max Size: 1MB" )
            } else {
                toast( "Invalid file format for profile" )
            }

        }
        e.target.value = ''
    }

    const handleCropSave = async ( file ) => {
        setLoadingImage( true )
        const fileObj = new File( [file], file.name, { type: file.type } )
        await StudentResumeFormStore.handleProfileFieldChange( section, attrkey, fileObj )
        await getImageUrl()
        setImageSrcForCropping( null )
        setLoadingImage( false )
    }

    useEffect( () => {
        const getImageUrl = async () => {
            if ( ( StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] || ( sectionResponseJson && sectionResponseJson[attrkey] ) ) ) {
                setLoadingImage( true )
                const key = StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] || sectionResponseJson[attrkey]
                setFileSrc( await StudentResumeFormStore.getFilePresignedUrl( key ) )
                setLoadingImage( false )
            }
        }
        getImageUrl()
    }, [StudentResumeFormStore, sectionResponseJson, attrkey, section] )

    return (
        <Box sx={{ flexGrow: "1", width: "100%" }}>
            <ProfileImageCropper maxSize='1MB' imageSrc={imageSrcForCropping} setImageSrc={setImageSrcForCropping} handleCropSave={handleCropSave} />
            <Box>
                <InputLabel> <Typography gutterBottom variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                <Box display="flex" gap="10px" alignItems="center">
                    {!loadingImage && fileSrc && <img width="50px" height="50px" alt='profile' src={fileSrc} style={{ objectFit: "cover", borderRadius: "50%" }} />}
                    {loadingImage && <Box position="relative">
                        <Avatar sx={{ pointerEvents: "null" }}></Avatar>
                        <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress size={30} />
                        </Box>
                    </Box>}
                    <Box
                        onDragOver={() => setIsDraggingOver( true )}
                        onDragLeave={() => setIsDraggingOver( false )}
                        onDragEnter={() => setIsDraggingOver( true )}
                        onDrop={() => setIsDraggingOver( false )}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        borderRadius="5px"
                        sx={{ background: isDraggingOver ? "#eee6" : "none", cursor: "pointer", "&:hover": { background: palette.rowHover } }}
                        border={isDraggingOver ? "5px dotted #d3d3d3" : "3px dotted #d3d3d3"}
                        position="relative"
                        padding="10px"
                    >
                        {<Box width="fit-content" sx={{ opacity: StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] ? "0.5" : "1", pointerEvents: !StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] ? "all" : "none" }} alignItems="center" display="flex" gap="10px">
                            {Icons.default.UploadFileIcon}
                            <Box>
                                <Typography gutterBottom variant='subtitle2' display="flex" alignItems="center" gap="20px" sx={{ textTransform: "capitalize", color: "textSecondary" }} > {fileSrc ? "Replace" : "Upload"} file</Typography>
                                <Typography fontSize="10px">SUPPORTED FORMATS: <b> <i>.JPG, .JPEG and .PNG</i> </b> </Typography>
                            </Box>
                        </Box>}
                        <Box sx={{ opacity: "0", position: "absolute", top: "0", right: "0", bottom: "0", left: "0" }}>
                            <input onChange={handleFileFieldChange} accept='image/gif,image/jpeg,image/png' style={{ width: "100%", height: "100%", cursor: "pointer" }} type='file' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {StudentResumeFormStore.getFileUploadWait[[`${section.section_title}-${attrkey}`]] && <Typography display="flex" alignItems="center" gap="10px" variant="subtitle2" color="GrayText"> <CircularProgress size={20} /> Uploading your file...  </Typography>}
        </Box> )
} )

export default ImageInput
