import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import addFileImg from '../../assets/add-file-img.svg'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import { validateQuestionsImportFormat, validateQuestionsImportFormatForQuestionBank } from '../../utils/exam-utilities'
import { toast } from 'react-toastify'
import { convert } from 'html-to-text'
import { Link } from 'react-router-dom'

const QuestionImportFileInputDialog = ( { fromComponent = "exam", bankId, topicId, setQuestionsPreview, importDialogStatus, questionsPreview, setImportDialogStatus, isImporting } ) => {

    const { palette } = useTheme()

    const handleDragEnter = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.add( 'drag' )
        } catch ( err ) { }
    }

    const handleDragLeave = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    const handleDrop = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    const onImportChange = async ( e ) => {
        if ( e.target.files[0].name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = fromComponent === "exam" ? validateQuestionsImportFormat( fileReader.result, "txt" ) : validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", bankId, topicId )
                setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
                e.target.value = ""
            }
            fileReader.readAsText( e.target.files[0] )
        } else if ( e.target.files[0].name.includes( ".docx" ) ) {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    setQuestionsPreview( { status: "loading" } )
                    const formData = new FormData()
                    formData.append( 'file', e.target.files[0] )
                    try {
                        const res = await api.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = fromComponent === "exam" ? validateQuestionsImportFormat( res.data, "docx" ) : validateQuestionsImportFormatForQuestionBank( res.data, "docx", bankId, topicId )
                        setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "doc", plainData: convert( fileReader.result ), errors, questions, errorLines } )
                        e.target.value = ''
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later" )
                    }
                }
                fileReader.readAsText( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
            }
        } else {
            toast( "Unsupported file format! Can only import questions from .docx or .txt files." )
        }
        setImportDialogStatus( false )
    }

    return (
        <Dialog open={importDialogStatus && !questionsPreview} PaperProps={{ sx: { width: "75vw", maxWidth: "600px" } }}>
            <DialogTitle sx={{ padding: "10px 20px", borderBottom: "1px solid #d3d3d3", display: "flex", fontSize: "16px", justifyContent: "space-between", alignItems: "center" }}>Upload the question file
                <IconButton size='small' onClick={() => setImportDialogStatus( false )} sx={{ textTransform: "capitalize" }}>{Icons.default.CloseIcon}</IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: "0" }}>
                <Box padding="20px">
                    <Box
                        id="file-upload-container"
                        height="200px"
                        borderRadius="10px"
                        position="relative"
                        overflow="hidden"
                        sx={{
                            background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke-width='2' stroke='${palette.primary.main?.replace( /#/, '%23' )}' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                            backgroundColor: palette.primary.light + "22"
                        }}
                    >
                        <Box display="flex" sx={{ background: "none" }} justifyContent="center" gap="10px" flexDirection="column" height="100%" alignItems="center">
                            <input onDrop={handleDrop} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} accept="text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document" disabled={isImporting} type="file" onChange={onImportChange} style={{ background: "none", position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} />
                            <img id='file-img' width="60" height="60" src={addFileImg} alt="document" />
                            <Typography variant='subtitle2'>Drag and drop your file here, or <Typography fontWeight="bold" display="inline-block" sx={{ color: "customThemeColor.dark" }}>Browse</Typography> </Typography>
                            <Typography fontSize="14px" fontWeight="500" color="textSecondary">Supports: <i>.docx and .txt</i> </Typography>
                        </Box>
                    </Box>
                    <i style={{ fontSize: "14px", display: "block", margin: "10px 0" }} >To know about the import file format <Link to='https://docs.moodle.org/401/en/Aiken_format' target={"_blank"} style={{ color: "purple", fontWeight: "bold", cursor: "pointer", textDecoration: "underline !important" }} >click here.</Link> </i>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default QuestionImportFileInputDialog
// border: 1px solid #139CFF