import { styled, Tooltip, tooltipClasses } from "@mui/material"


const DarkTooltip = styled( ( { className, ...props } ) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
) )( ( { theme } ) => ( {
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.font,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.font,
        color: theme.palette.common.bg,
        padding: "10px",
        fontStyle: "italic",
        borderRadius: "10px",
    },
} ) )

export default DarkTooltip