import axios from "axios"
export const isProd = true
const baseURL = isProd ? 'https://alive-core-api.alive.university/api/v1' : 'http://localhost:5500/api/v1'
const studyMaterialBaseURL = isProd ? 'https://studymaterial-api.alive.university/api' : 'http://localhost:5600/api'
const examBaseURL = isProd ? `https://exam-api.alive.university/api` : 'http://localhost:5700/api'
const resumeBuilderBaseUrl = isProd ? "https://resume-api.alive.university/api" : `http://localhost:5800/api`
const onlineTutorialBaseUrl = isProd ? "https://alivecourse-api.alive.university/api" : `http://localhost:5400/api`

const discussionForumBaseUrl = isProd ? "https://alive-discussionforum-api.alive.university/api" : `http://localhost:6500/api`
const liveSessionURL = isProd ? "https://alive-livesession-api.alive.university/api" : `http://localhost:6400/api`

export const blobBaseUrl = `sgp1.digitaloceanspaces.com/content-ai/`

const headers = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem( 'id_token' )
    }
}

const headersFormdata = () => {
    return {
        'Accept': 'application/json',
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + localStorage.getItem( 'id_token' )
    }
}

const api = {
    signUp: ( payload ) => {
        return axios.post( baseURL + `/signup`, payload )
    },
    signIn: ( payload ) => {
        return axios.post( baseURL + `/signin`, payload )
    },
    signInERP: ( payload ) => {
        return axios.post( baseURL + `/login/erp`, payload )
    },
    logout: () => {
        return axios.post( baseURL + `/logout`, {}, { headers: headers() } )
    },
    signInExam: ( payload ) => {
        return axios.post( `${examBaseURL}/users/login`, payload )
    },
    fetchSubjectFeed: ( subject_id ) => {
        return axios.get( `${baseURL}/feed`, { headers: headers(), params: { subject_id } } )
    },

    // Manage Accounts
    getUser: () => {
        return axios.get( baseURL + `/user`, { headers: headers() } )
    },
    updateUserProfileImage: ( payload ) => {
        return axios.put( baseURL + `/user`, payload, { headers: headersFormdata() } )
    },
    getUsers: () => {
        return axios.get( baseURL + `/user-accounts`, { headers: headers() } )
    },
    getUserSubjects: () => {
        return axios.get( baseURL + `/user-subjects`, { headers: headers() } )
    },
    getSubAdminAccounts: () => {
        return axios.get( baseURL + '/subadmin', { headers: headers() } )
    },
    findUserDebugTimetable: ( payload ) => {
        return axios.post( baseURL + `/find-user-timetable`, payload, { headers: headers() } )
    },
    createUserAccount: ( payload ) => {
        return axios.post( baseURL + `/user`, payload, { headers: headers() } )
    },
    createSubAdminAccount: ( payload ) => {
        return axios.post( baseURL + '/subadmin', payload, { headers: headers() } )
    },
    updateUserAccount: ( payload, id ) => {
        return axios.put( baseURL + `/user/${id}`, payload, { headers: headers() } )
    },
    updateSubAdminAccount: ( payload, id ) => {
        return axios.put( baseURL + `/subadmin/${id}`, payload, { headers: headers() } )
    },
    updateUserStatus: ( payload ) => {
        return axios.put( baseURL + `/user-status`, payload, { headers: headers() } )
    },
    deleteSubAdminAccount: ( id ) => {
        return axios.delete( baseURL + `/subadmin/${id}`, { headers: headers() } )
    },
    sendFeedback: ( payload ) => {
        return axios.post( baseURL + `/feedback`, payload, { headers: headers() } )
    },
    createAcademicRule: ( payload ) => {
        return axios.post( `${baseURL}/academic-rule`, payload, { headers: headers() } )
    },
    fetchAcademicChecklistDepartments: () => {
        return axios.get( `https://www.acharyainstitutes.in/index.php?r=acerp-api/lms-courses-names` )
    },
    fetchAllAcademicYears: () => {
        return axios.get( `https://acharyainstitutes.in/index.php?r=acerp-api/acyears&lmsflag=1` )
    },
    fetchAcademicRules: () => {
        return axios.get( `${baseURL}/academic-rule`, { headers: headers() } )
    },
    fetchStudentAcademicRules: ( params = {} ) => {
        return axios.get( `${baseURL}/academic-rule`, { headers: headers(), params: params } )
    },
    deleteAcademicRule: ( id ) => {
        return axios.delete( `${baseURL}/academic-rule/${id}`, { headers: headers() } )
    },
    createAcademicRuleChecklist: ( id, payload ) => {
        return axios.post( `${baseURL}/academic-rule/${id}`, payload, { headers: headers() } )
    },
    importChecklistItems: ( id, payload ) => {
        return axios.put( `${baseURL}/academic-rule/${id}`, payload, { headers: headers() } )
    },
    deleteChecklistItem: ( ids ) => {
        return axios.post( `${baseURL}/academic-rule/checklist/delete`, { ids }, { headers: headers() } )
    },
    updateChecklistItem: ( id, payload ) => {
        return axios.put( `${baseURL}/academic-rule/edit/${id}`, payload, { headers: headers() } )
    },
    getDepartmentAcademicRuleChecklist: ( id ) => {
        return axios.get( `${baseURL}/academic-rule/${id}`, { headers: headers() } )
    },
    getInstituteAcademicRuleChecklist: ( institute ) => {
        return axios.get( `${baseURL}/academic-rule/institute/${institute}`, { headers: headers() } )
    },
    getUniqueChecklistItems: ( institute ) => {
        return axios.get( `${baseURL}/academic-rule/checklist/unique`, { headers: headers() } )
    },
    getBranchStudentsProgressReport: ( payload, params ) => {
        return axios.post( `${baseURL}/academics/audit`, payload, { headers: headers(), params } )
    },
    fetchStudentAcademicRulesForAudit: ( id, params ) => {
        return axios.get( `${baseURL}/academics/audit/${id}`, { headers: headers(), params } )
    },

    // Academic program auditors
    fetchFacultyDetailsById: ( id ) => {
        return axios.get( `${baseURL}/academics/faculty/${id}`, { headers: headers() } )
    },
    createAcademicProgramAuditor: ( payload ) => {
        return axios.post( `${baseURL}/academics/auditor`, payload, { headers: headers() } )
    },
    updateAcademicProgramAuditor: ( id, payload ) => {
        return axios.put( `${baseURL}/academics/auditor/${id}`, payload, { headers: headers() } )
    },
    getAllAcademicProgramAuditors: () => {
        return axios.get( `${baseURL}/academics/auditor`, { headers: headers() } )
    },
    getAllAcademicProgramAuditorAllowedBranches: () => {
        return axios.get( `${baseURL}/academics/auditor/allowed-branches`, { headers: headers() } )
    },
    deleteProgramAuditor: ( id ) => {
        return axios.delete( `${baseURL}/academics/auditor/${id}`, { headers: headers() } )
    },
    downloadAcademicsStudentDataAttachment: ( key ) => {
        return axios.post( `${baseURL}/academics/student-data/attachment`, { key }, { headers: headers(), responseType: 'blob' } )
    },
    uploadAcademicsDocument: ( payload ) => {
        return axios.post( `${baseURL}/academic-rule/submit-document`, payload, { headers: headersFormdata() } )
    },

    // Batch
    getAllBatchAcademicYear: ( params ) => {
        return axios.get( baseURL + `/batch/get-academic-year`, { headers: headers(), params } )
    },
    getAllBatch: ( params ) => {
        return axios.get( baseURL + `/batch`, { headers: headers(), params } )
    },
    getAllSubjects: () => {
        return axios.get( `${studyMaterialBaseURL}/user/subjects`, { headers: headers() } )
    },
    // Notes
    uploadNotes: ( payload ) => {
        return axios.post( baseURL + `/notes`, payload, { headers: headersFormdata() } )
    },
    deleteNotes: ( payload ) => {
        return axios.post( baseURL + `/notes/delete`, payload, { headers: headers() } )
    },
    fetchNotesByAuthor: () => {
        return axios.get( baseURL + `/notes/teacher`, { headers: headers() } )
    },
    fetchNotesByStudent: () => {
        return axios.get( baseURL + `/notes/student`, { headers: headers() } )
    },
    downloadNotes: ( url ) => {
        return axios.get( baseURL + `/notes/download?sourceUrl=${url}`, { headers: headers(), responseType: 'blob' } )
    },

    // Study material
    uploadStudyMaterial: ( payload, progressHandler ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material`, payload, { headers: headersFormdata(), onUploadProgress: progressHandler } )
    },
    deleteStudyMaterial: ( id ) => {
        return axios.delete( `${studyMaterialBaseURL}/study-material/${id}`, { headers: headers() } )
    },
    getMaterials: ( institute ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material`, { headers: headers(), params: { institute } } )
    },
    getMaterialsBySubject: ( subject ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/subject-materials`, { headers: headers(), params: { subject } } )
    },
    getBranchMaterials: ( institute, code ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${institute}/branch/${code}`, { headers: headers() } )
    },
    getInstituteMaterials: ( institute ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/institute/${institute}`, { headers: headers() } )
    },
    getMaterial: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}`, { headers: headers() } )
    },
    updateMaterial: ( id, payload ) => {
        return axios.put( `${studyMaterialBaseURL}/study-material/${id}`, payload, { headers: headers() } )
    },
    downloadMaterial: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/download`, { headers: headers() } )
    },
    getMaterialBatches: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/batches`, { headers: headers() } )
    },
    getAttachments: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/attachments`, { headers: headers() } )
    },
    uploadAttachments: ( id, payload, progressHandler ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/add_attachments`, payload, { headers: headersFormdata(), onUploadProgress: progressHandler } )
    },
    renameAttachment: ( material_id, id, payload ) => {
        return axios.put( `${studyMaterialBaseURL}/study-material/${material_id}/attachment/${id}`, payload, { headers: headers() } )
    },
    deleteAttachment: ( id ) => {
        return axios.delete( `${studyMaterialBaseURL}/study-material/attachment/${id}`, { headers: headers() } )
    },
    downloadAttachment: ( id, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/attachment/${id}/download`, payload, { headers: headers() } )
    },
    grantAccess: ( id, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/grant`, payload, { headers: headers() } )
    },
    revokeAccess: ( id, accessId, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/revoke/${accessId}`, payload, { headers: headers() } )
    },

    // Exams

    fetchExamInstructions: ( params ) => {
        return axios.get( `${examBaseURL}/exams/instructions`, { headers: headers(), params } )
    },
    updateExamInstructions: ( payload ) => {
        return axios.post( `${examBaseURL}/exams/instructions`, payload, { headers: headers() } )
    },
    getExam: ( id ) => {
        return axios.get( `${examBaseURL}/exams/${id}`, { headers: headers() } )
    },
    fetchExamsByDept: ( deptCode ) => {
        return axios.get( `${examBaseURL}/exams/dept/${deptCode}`, { headers: headers() } )
    },
    fetchExamsByInstitute: ( institute ) => {
        return axios.get( `${examBaseURL}/exams/institute/${institute}`, { headers: headers() } )
    },
    fetchExamsBySubject: ( subject ) => {
        return axios.get( `${examBaseURL}/exams/get-by-subject`, { headers: headers(), params: { subject } } )
    },
    fetchPendingExams: ( params ) => {
        return axios.get( `${examBaseURL}/exams/pending`, { headers: headers(), params } )
    },
    fetchExamEvents: ( params = {} ) => {
        return axios.get( `${examBaseURL}/exams/events-timeline`, { headers: headers(), params } )
    },
    getAllExams: () => {
        return axios.get( `${examBaseURL}/exams/`, { headers: headers() } )
    },
    getInstituteExamStats: ( institute ) => {
        return axios.get( `${examBaseURL}/exams/institute/${institute}/stats`, { headers: headers() } )
    },
    createExam: ( payload ) => {
        return axios.post( `${examBaseURL}/exams/`, payload, { headers: headersFormdata() } )
    },
    updateExam: ( id, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}`, payload, { headers: headersFormdata() } )
    },
    rescheduleExam: ( id, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/reschedule`, payload, { headers: headers() } )
    },
    convertDocxToHtml: ( payload ) => {
        return axios.post( `${examBaseURL}/exams/convert-doc-to-txt`, payload )
    },
    addQuestion: ( id, payload ) => {
        return axios.post( `${examBaseURL}/exams/${id}/addquestion`, payload, { headers: headersFormdata() } )
    },
    updateQuestion: ( id, qid, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/question/${qid}`, payload, { headers: headersFormdata() } )
    },
    deleteQuestion: ( id, qid ) => {
        return axios.delete( `${examBaseURL}/exams/${id}/question/${qid}`, { headers: headers() } )
    },
    addOption: ( id, qid, payload ) => {
        return axios.post( `${examBaseURL}/exams/${id}/question/${qid}`, payload, { headers: headers() } )
    },
    removeOption: ( id, qid, oid ) => {
        return axios.delete( `${examBaseURL}/exams/${id}/question/${qid}/option/${oid}`, { headers: headers() } )
    },
    updateOption: ( id, qid, oid, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/question/${qid}/option/${oid}`, payload, { headers: headers() } )
    },
    fetchQuestions: ( id ) => {
        return axios.get( `${examBaseURL}/exams/${id}/questions`, { headers: headers() } )
    },
    fetchQuestionsForAttempt: ( id ) => {
        return axios.get( `${examBaseURL}/exams/${id}/questions/attempt`, { headers: headers() } )
    },
    getAudioPresignedUrl: ( exam_id, question_id, payload ) => {
        return axios.post( `${examBaseURL}/exams/${exam_id}/question/${question_id}/fetch-attachment`, payload, { headers: headers() } )
    },
    publish: ( id ) => {
        return axios.put( `${examBaseURL}/exams/publish/${id}`, {}, { headers: headers() } )
    },
    deleteExam: ( id ) => {
        return axios.delete( `${examBaseURL}/exams/${id}`, { headers: headers() } )
    },
    attempt: ( id ) => {
        return axios.post( `${examBaseURL}/exams/${id}/attempt`, {}, { headers: headers() } )
    },
    startExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/start/${pid}`, payload || {}, { headers: headers() } )
    },
    finishExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/participation/${pid}/finish`, payload || {}, { headers: headers() } )
    },
    registerExamAttemptFault: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/exams/${id}/participation/${pid}/registerfault`, payload || {}, { headers: headers() } )
    },
    fetchUserParticipations: () => {
        return axios.get( `${examBaseURL}/users/participation`, { headers: headers() } )
    },
    fetchUserParticipation: ( id ) => {
        return axios.get( `${examBaseURL}/users/participation/${id}`, { headers: headers() } )
    },
    fetchExamParticipation: ( id ) => {
        return axios.get( `${examBaseURL}/exams/${id}/participation`, { headers: headers() } )
    },
    fetchAllParticipations: () => {
        return axios.get( `${examBaseURL}/exams/participations/all`, { headers: headers() } )
    },
    fetchParticipationData: ( id, pid ) => {
        return axios.get( `${examBaseURL}/exams/${id}/participation/${pid}`, { headers: headers() } )
    },
    fetchParticipationSubmissions: ( id, pid ) => {
        return axios.get( `${examBaseURL}/exams/${id}/submissions/${pid}`, { headers: headers() } )
    },
    submitAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}/exams/${id}/submissions/${pid}`, payload, { headers: headers() } )
    },
    submitRemark: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}/exams/${id}/participation/${pid}/remark`, payload, { headers: headers() } )
    },
    fetchEvaluationScheme: ( id ) => {
        return axios.get( `${examBaseURL}/exams/${id}/evaluationscheme`, { headers: headers() } )
    },

    // assignment
    uploadAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment`, payload, { headers: headersFormdata() } )
    },
    ///assignment/update
    updateAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment/update`, payload, { headers: headers() } )
    },
    deleteAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment/delete`, payload, { headers: headers() } )
    },
    uploadAssignmentSubmission: ( payload, params ) => {
        return axios.post( baseURL + `/assignment/submission`, payload, { headers: headersFormdata(), params } )
    },
    deleteAssignmentSubmitted: ( payload ) => {
        return axios.post( baseURL + `/assignment/submission/delete`, payload, { headers: headers() } )
    },
    setAssignmentScore: ( payload ) => {
        return axios.post( baseURL + `/assignment/score`, payload, { headers: headers() } )
    },
    setAssignmentRemarks: ( payload ) => {
        return axios.post( baseURL + `/assignment/remark`, payload, { headers: headers() } )
    },
    fetchAssignmentSubmission: ( assignment_id ) => {
        return axios.get( baseURL + `/assignment/submission?assignment_id=${assignment_id}`, { headers: headers() } )
    },
    fetchAssignmentByAuthor: () => {
        return axios.get( baseURL + `/assignment/teacher`, { headers: headers() } )
    },
    fetchAssignmentByStudent: () => {
        return axios.get( baseURL + `/assignment/student`, { headers: headers() } )
    },
    fetchSubjectAssignment: ( params = {} ) => {
        return axios.get( `${baseURL}/assignment/`, { headers: headers(), params } )
    },
    fetchDueOrRecentAssignment: ( params = {} ) => {
        return axios.get( `${baseURL}/assignment/recentorpending`, { headers: headers(), params } )
    },
    fetchAssignmentEvents: ( params = {} ) => {
        return axios.get( `${baseURL}/assignment/events`, { headers: headers(), params } )
    },
    downloadAssignment: ( url, signedUrl ) => {
        return axios.get( baseURL + `/assignment/download?sourceUrl=${url}&preSignedUrl=${signedUrl}`, { headers: headers(), responseType: !( signedUrl === true || signedUrl === 1 ) && 'blob' } )
    },

    // Proctoring
    fetchProctorAcademicYear: () => {
        return axios.get( `${baseURL}/proctees/academic-years`, { headers: headers() } )
    },
    fecthProcteeDetails: ( id ) => {
        return axios.get( `${baseURL}/proctee/${id}`, { headers: headers() } )
    },
    fetchProcteeAcademicRuleDetails: ( id, params ) => {
        return axios.get( `${baseURL}/academic-rule/proctee/${id}`, { headers: headers(), params } )
    },
    removeStudentAttachment: ( payload ) => {
        return axios.post( `${baseURL}/proctee/delete-attachment/`, payload, { headers: headers() } )
    },
    submitChecklistStudentData: ( id, payload ) => {
        return axios.post( `${baseURL}/proctee/submit`, payload, { headers: headersFormdata() } )
    },
    fetchChecklistStudentData: ( payload ) => {
        return axios.post( `${baseURL}/proctee/data/${payload.student_id}`, payload, { headers: headersFormdata() } )
    },
    resetChecklistData: ( payload ) => {
        return axios.put( `${baseURL}/proctee/reset-data/`, payload, { headers: headers() } )
    },

    //Org
    //https://www.acharyainstitutes.in/index.php?r=acerp-api/institutes
    getAllOrganization: () => {
        return axios.get( "https://www.acharyainstitutes.in/index.php?r=acerp-api/institutes" )
    },
    getAllOrganizationBranches: ( instituteCode ) => {
        return axios.get( studyMaterialBaseURL + `/institute/${instituteCode}/branches` )
    },
    getOrganizationStats: ( instituteCode ) => {
        return axios.get( studyMaterialBaseURL + `/institute/${instituteCode}/stats`, { headers: headers() } )
    },
    // getOrganization: ()=>{
    //     return axios.get(baseURL+`/organization`, { headers : headers() });
    // },
    getOrganization: () => {
        return axios.get( baseURL + `/organization`, { headers: headers() } )
    },
    createOrganization: ( payload ) => {
        return axios.post( baseURL + `/organization`, payload, { headers: headers() } )
    },
    updateOrganization: ( payload ) => {
        return axios.put( baseURL + `/organization`, payload, { headers: headers() } )
    },
    deleteOrganization: ( payload ) => {
        return axios.delete( baseURL + `/organization`, payload, { headers: headers() } )
    },

    // department
    getAllDepartment: () => {
        return axios.get( baseURL + `/all-department` )
    },
    getDepartment: () => {
        return axios.get( baseURL + `/department`, { headers: headers() } )
    },
    createDepartment: ( payload ) => {
        return axios.post( baseURL + `/department`, payload, { headers: headers() } )
    },
    updateDepartment: ( payload ) => {
        return axios.put( baseURL + `/department`, payload, { headers: headers() } )
    },
    deleteDepartment: ( id ) => {
        //console.log(headers)
        return axios.delete( baseURL + `/department/${id}`, { headers: headers() } )
    },
    // class room with erp
    startSession: ( payload ) => {
        return axios.post( baseURL + `/start-session`, payload, { headers: headers() } )
    },
    joinSession: ( payload ) => {
        return axios.post( baseURL + `/join-session`, payload, { headers: headers() } )
    },
    joinSessionAsModerator: ( payload ) => {
        return axios.post( baseURL + `/join-session-moderator`, payload, { headers: headers() } )
    },
    endSession: ( payload ) => {
        return axios.post( baseURL + `/end-session`, payload, { headers: headers() } )
    },
    fetchFeedbackQuestions: () => {
        return axios.get( baseURL + `/session/feedback/questions`, { headers: headers() } )
    },
    submitSessionFeedback: ( id, payload ) => {
        return axios.post( `${baseURL}/session/${id}/feedback`, payload, { headers: headers() } )
    },
    addFeedbackQuestion: ( payload ) => {
        return axios.post( `${baseURL}/session/feedback/question`, payload, { headers: headers() } )
    },
    fetchFeedbacks: ( payload ) => {
        return axios.post( `${baseURL}/feedbacks`, payload, { headers: headers() } )
    },
    updateFeedbackQuestion: ( id, payload ) => {
        return axios.put( `${baseURL}/session/feedback/question/${id}`, payload, { headers: headers() } )
    },
    deleteFeedbackQuestion: ( id ) => {
        return axios.delete( `${baseURL}/session/feedback/question/${id}`, { headers: headers() } )
    },
    getFeedbackSessions: () => {
        return axios.get( `${baseURL}/feedback/sessions`, { headers: headers() } )
    },
    fetchStudentFeedbacks: () => {
        return axios.get( `${baseURL}/student/feedbacks`, { headers: headers() } )
    },
    getMySession: () => {
        return axios.get( baseURL + `/sessions`, { headers: headers() } )
    },
    getClassesByDate: ( date = undefined ) => {
        return axios.get( `${baseURL}/classes`, { headers: headers(), params: { date } } )
    },
    getAllRecording: ( icode ) => {
        return axios.get( baseURL + `/recording?institute=` + icode, { headers: headers() } )
    },
    getFacultyRecording: () => {
        return axios.get( baseURL + `/recording/faculty`, { headers: headers() } )
    },
    auditSession: ( icode ) => {
        return axios.get( baseURL + `/audit-session?institute_name_short=` + icode, { headers: headers() } )
    },
    auditFeedback: ( payload ) => {
        return axios.post( baseURL + `/audit-feedback-log`, payload, { headers: headers() } )
    },
    ///exam/teacher
    // examFetch: ( payload ) => {
    //     return axios.get( baseURL + `/exam`, { headers: headers() } )
    // },

    // fetchExamSubmission: ( id ) => {
    //     return axios.get( baseURL + `/exam/submission?exam_id=${id}`, { headers: headers() } )
    // },
    // examStudent: ( payload ) => {
    //     return axios.get( baseURL + `/exam/student`, { headers: headers() } )
    // },
    // submitExamAnswers: ( payload ) => {
    //     return axios.post( baseURL + `/exam/submission`, payload, { headers: headersFormdata() } )
    // },
    // assignScore: ( payload ) => {
    //     return axios.post( baseURL + `/exam/score`, payload, { headers: headers() } )
    // },


    // room
    getOrgRoom: ( payload ) => {
        return axios.post( baseURL + `/getrooms`, payload, { headers: headers() } )
    },
    getRoom: () => {
        return axios.get( baseURL + `/room`, { headers: headers() } )
    },
    createRoom: ( payload ) => {
        return axios.post( baseURL + `/room`, payload, { headers: headers() } )
    },
    updateRoom: ( payload ) => {
        return axios.put( baseURL + `/room`, payload, { headers: headers() } )
    },
    deleteRoom: ( api_id ) => {
        return axios.delete( baseURL + `/room/${api_id}`, { headers: headers() } )
    },
    getSessionClassAttendence: ( payload ) => {
        return axios.post( baseURL + `/attentence-session`, payload, { headers: headers() } )
    },
    searchAttendence: ( payload ) => {
        return axios.post( baseURL + `/attendence/search`, payload, { headers: headers() } )
    },
    attendenceAudit: ( payload ) => {
        return axios.post( baseURL + `/attendance/audit`, payload, { headers: headers() } )
    },
    ///attendence/student
    studentAttendence: ( payload ) => {
        return axios.post( baseURL + `/attendence/student`, payload, { headers: headers() } )
    },
    // api settings -->> Start
    createApiProvider: ( payload ) => {
        return axios.post( baseURL + `/provider-api`, payload, { headers: headers() } )
    },
    getApiProviders: () => {
        return axios.get( baseURL + `/provider-api`, { headers: headers() } )
    },
    updateApiProvider: ( payload, api_id ) => {
        return axios.put( baseURL + `/provider-api/${api_id}`, payload, { headers: headers() } )
    },
    deleteApiProvider: ( api_id ) => {
        return axios.delete( baseURL + `/provider-api/${api_id}`, { headers: headers() } )
    },
    // api settings -->> End
    // analytics -->> Start
    getNodes: () => {
        return axios.get( baseURL + `/nodes`, { headers: headers() } )
    },

    getServerStat: () => {
        return axios.get( baseURL + `/serverstat` )
    },

    doLogout: () => {
        localStorage.removeItem( "isLoggedIn" )
        //return false;
    },
    isLoggedIn: () => {
        return localStorage.getItem( "isLoggedIn" )
    },
    createUser: ( payload ) => {
        return axios.post( baseURL + `/user`, payload )
    },
    updateUser: ( payload, id ) => {
        return axios.put( baseURL + `/user/${id}`, payload )
    },
    updatePassword: ( payload ) => {
        return axios.put( baseURL + `/change-password`, payload, { headers: headers() } )
    },
    deleteUser: ( id ) => {
        return axios.delete( baseURL + `/user/${id}` )
    },

    // Institute routes
    getInstituteFacultyCount: ( institute ) => {
        return axios.get( baseURL + `/stats/faculty/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteStudentCount: ( institute ) => {
        return axios.get( baseURL + `/stats/student/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteBranchCount: ( institute ) => {
        return axios.get( baseURL + `/stats/branch/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteBranchUserCount: ( institute ) => {
        return axios.get( baseURL + `/stats/branchuser/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteAssignmentCount: ( institute ) => {
        return axios.get( baseURL + `/stats/assignment/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteStudyMaterialCount: ( institute ) => {
        return axios.get( studyMaterialBaseURL + `/audit/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteExamCount: ( institute ) => {
        return axios.get( examBaseURL + `exams/stats/count?institute=${institute}`, { headers: headers() } )
    },

    // Online Tutorials
    addOnlineCourse: ( payload ) => {
        return axios.post( `${onlineTutorialBaseUrl}/online-course`, payload, { headers: headersFormdata() } )
    },
    updateCourse: ( course_id, payload ) => {
        return axios.put( `${onlineTutorialBaseUrl}/online-course/${course_id}`, payload, { headers: headersFormdata() } )
    },
    addNewModule: ( course_id, payload ) => {
        return axios.post( `${onlineTutorialBaseUrl}/online-course/${course_id}/module`, payload, { headers: headers() } )
    },
    enroll: ( course_id, payload ) => {
        return axios.post( `${onlineTutorialBaseUrl}/onine-course/${course_id}/enroll`, payload, { headers: headers() } )
    },
    getEnrolledCourses: () => {
        return axios.get( `${onlineTutorialBaseUrl}/onine-course/enrolled`, { headers: headers() } )
    },
    getEnrolledCoursesProgress: () => {
        return axios.get( `${onlineTutorialBaseUrl}/progress/enrolled`, { headers: headers() } )
    },
    getCourseProgressData: ( progress_id ) => {
        return axios.get( `${onlineTutorialBaseUrl}/progress/${progress_id}`, { headers: headers() } )
    },
    getCourseEnrollmentStatus: ( course_id ) => {
        return axios.get( `${onlineTutorialBaseUrl}/onine-course/${course_id}/enroll`, { headers: headers() } )
    },
    updateModuleName: ( module_id, payload ) => {
        return axios.put( `${onlineTutorialBaseUrl}/module/${module_id}`, payload, { headers: headers() } )
    },
    addAttachment: ( module_id, payload, progressHandler ) => {
        return axios.post( `${onlineTutorialBaseUrl}/module/${module_id}`, payload, { headers: headersFormdata(), onDownloadProgress: progressHandler } )
    },
    getCourseAttachment: ( id ) => {
        return axios.get( `${onlineTutorialBaseUrl}/attachment/${id}`, { headers: headersFormdata() } )
    },
    reorderCourseModules: ( newOrder ) => {
        return axios.put( `${onlineTutorialBaseUrl}/online-course/modules`, { newOrder }, { headers: headers() } )
    },
    deleteCourseAttachment: ( id ) => {
        return axios.delete( `${onlineTutorialBaseUrl}/attachment/${id}`, { headers: headers() } )
    },
    getCourses: () => {
        return axios.get( `${onlineTutorialBaseUrl}/online-course`, { headers: headers() } )
    },
    getSubjectCourses: ( params ) => {
        return axios.get( `${onlineTutorialBaseUrl}/online-course/subject`, { headers: headers(), params } )
    },
    getCourse: ( id ) => {
        return axios.get( `${onlineTutorialBaseUrl}/online-course/${id}`, { headers: headers() } )
    },
    startCourse: ( id ) => {
        return axios.put( `${onlineTutorialBaseUrl}/progress/${id}/start`, { progress_id: id }, { headers: headers() } )
    },
    startAttachment: ( id, payload ) => {
        return axios.post( `${onlineTutorialBaseUrl}/progress/${id}`, payload, { headers: headers() } )
    },
    updateProgressDetail: ( id, payload ) => {
        return axios.put( `${onlineTutorialBaseUrl}/progress/update/${id}`, payload, { headers: headers() } )
    },
    deleteModule: ( id ) => {
        return axios.delete( `${onlineTutorialBaseUrl}/module/${id}`, { headers: headers() } )
    },
    publishCourse: ( id ) => {
        return axios.put( `${onlineTutorialBaseUrl}/online-course/${id}/publish`, {}, { headers: headers() } )
    },
    updateAttachmentName: ( id, payload ) => {
        return axios.put( `${onlineTutorialBaseUrl}/attachment/${id}`, payload, { headers: headers() } )
    },
    deleteCourse: ( id ) => {
        return axios.delete( `${onlineTutorialBaseUrl}/online-course/${id}`, { headers: headers() } )
    },

    // Resume Builder
    createResumeForm: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/forms`, payload, { headers: headers() } )
    },
    fetchResumeForms: () => {
        return axios.get( `${resumeBuilderBaseUrl}/forms`, { headers: headers() } )
    },
    fetchResumeForm: ( id ) => {
        return axios.get( `${resumeBuilderBaseUrl}/forms/${id}`, { headers: headers() } )
    },
    fetchInstituteFormStatus: ( institute ) => {
        return axios.get( `${resumeBuilderBaseUrl}/forms/institute_status?institute=${institute}`, { headers: headers() } )
    },
    fetchStudentForm: () => {
        return axios.get( `${resumeBuilderBaseUrl}/forms/student-form`, { headers: headers() } )
    },
    deleteResumeForm: ( id ) => {
        return axios.delete( `${resumeBuilderBaseUrl}/forms/${id}`, { headers: headers() } )
    },
    updateResumeForm: ( id, payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/forms/${id}`, payload, { headers: headers() } )
    },
    updateResumeFormJson: ( id, payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/forms/${id}/fields`, payload, { headers: headers() } )
    },
    publishResumeForm: ( id ) => {
        return axios.put( `${resumeBuilderBaseUrl}/forms/${id}/publish`, { id: id }, { headers: headers() } )
    },
    createResumeEntity: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/entity`, payload, { headers: headers() } )
    },
    fetchResumeEntities: () => {
        return axios.get( `${resumeBuilderBaseUrl}/entities`, { headers: headers() } )
    },
    fetchResumeEntity: ( id ) => {
        return axios.get( `${resumeBuilderBaseUrl}/entity/${id}`, { headers: headers() } )
    },
    deleteResumeEntity: ( id ) => {
        return axios.delete( `${resumeBuilderBaseUrl}/entity/${id}`, { headers: headers() } )
    },
    updateResumeEntity: ( payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/entity/${payload.id}`, payload, { headers: headers() } )
    },
    fetchStudentResumeInfo: () => {
        return axios.get( `${resumeBuilderBaseUrl}/student-info`, { headers: headers() } )
    },
    fetchStudentResumeProgress: () => {
        return axios.get( `${resumeBuilderBaseUrl}/student-info/progress`, { headers: headers() } )
    },
    uploadStudentProfileToResume: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/student-info/profile-image`, payload, { headers: headersFormdata() } )
    },
    uploadStudentDocToResume: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/student-info/document`, payload, { headers: headersFormdata() } )
    },
    deleteResumeDocuments: ( payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/student-info/deletedocuments`, payload, { headers: headers() } )
    },
    getResumeFilePresignedUrl: ( key ) => {
        return axios.post( `${resumeBuilderBaseUrl}/getfile`, { key }, { headers: headers() } )
    },
    updateStudentResumeInfo: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/student-info`, payload, { headers: headers() } )
    },
    clearResumeSection: ( payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/student-info/clearsection`, payload, { headers: headers() } )
    },
    clearResumeData: ( payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/student-info/clear`, payload, { headers: headers() } )
    },
    createTemplate: ( payload ) => {
        return axios.post( `${resumeBuilderBaseUrl}/templates`, payload, { headers: headers() } )
    },
    getTemplates: ( institute ) => {
        if ( institute )
            return axios.get( `${resumeBuilderBaseUrl}/templates`, { headers: headers() } )
        else
            return axios.get( `${resumeBuilderBaseUrl}/templates?institute=${institute}`, { headers: headers() } )
    },
    deleteResumeTemplate: ( id ) => {
        return axios.delete( `${resumeBuilderBaseUrl}/templates/${id}`, { headers: headers() } )
    },
    updateTemplate: ( id, payload ) => {
        return axios.put( `${resumeBuilderBaseUrl}/templates/${id}`, payload, { headers: headers() } )
    },
    getTemplate: ( id ) => {
        return axios.get( `${resumeBuilderBaseUrl}/templates/${id}`, { headers: headers() } )
    },

    // Settings
    addSetting: ( payload ) => {
        return axios.post( `${baseURL}/setting`, payload, { headers: headers() } )
    },
    updateSetting: ( id, payload ) => {
        return axios.put( `${baseURL}/setting/${id}`, payload, { headers: headers() } )
    },
    updateSettingDetails: ( id, payload ) => {
        return axios.put( `${baseURL}/setting/${id}/edit`, payload, { headers: headers() } )
    },
    getSettings: ( query ) => {
        return axios.get( `${baseURL}/setting`, { params: query, headers: headers() } )
    },
    addSettingsModule: ( payload ) => {
        return axios.post( `${baseURL}/setting/module`, payload, { headers: headers() } )
    },
    getSettingsModules: () => {
        return axios.get( `${baseURL}/setting/module`, { headers: headers() } )
    },
    deleteSettings: ( id ) => {
        return axios.delete( `${baseURL}/setting/${id}`, { headers: headers() } )
    },
}

api.temporaryUsers = {
    fetchBatches: () => {
        return axios.get( `${baseURL}/temporary-users/batch`, { headers: headers() } )
    },
    addBatch: ( payload ) => {
        return axios.post( `${baseURL}/temporary-users/batch`, payload, { headers: headers() } )
    },
    addUser: ( id, payload ) => {
        return axios.post( `${baseURL}/temporary-users/batch/${id}`, payload, { headers: headers() } )
    },
    deleteBatch: ( id ) => {
        return axios.delete( `${baseURL}/temporary-users/batch/${id}`, { headers: headers() } )
    },
    fetchBatchUsers: ( id ) => {
        return axios.get( `${baseURL}/temporary-users/batch/${id}`, { headers: headers() } )
    },
    deleteUsers: ( ids ) => {
        return axios.post( `${baseURL}/temporary-users/delete`, ids, { headers: headers() } )
    }

}

api.discussionForum = {
    createDiscussion: ( payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion`, payload, { headers: headers() } )
    },
    updateDiscussion: ( id, payload ) => {
        return axios.put( `${discussionForumBaseUrl}/discussion/${id}`, payload, { headers: headers() } )
    },
    fetchDiscussions: ( params ) => {
        return axios.get( `${discussionForumBaseUrl}/discussion`, { headers: headers(), params } )
    },
    fetchUserDiscussions: () => {
        return axios.get( `${discussionForumBaseUrl}/user/discussions`, { headers: headers() } )
    },
    fetchDiscussion: ( id ) => {
        return axios.get( `${discussionForumBaseUrl}/discussion/${id}`, { headers: headers() } )
    },
    fetchDiscussionComment: ( id ) => {
        return axios.get( `${discussionForumBaseUrl}/discussion/${id}/comments`, { headers: headers() } )
    },
    deleteDiscussion: ( id ) => {
        return axios.delete( `${discussionForumBaseUrl}/discussion/${id}`, { headers: headers() } )
    },
    deleteComment: ( id ) => {
        return axios.delete( `${discussionForumBaseUrl}/discussion/comment/${id}`, { headers: headers() } )
    },
    getSubjects: () => {
        return axios.get( `${discussionForumBaseUrl}/user/subjects`, { headers: headers() } )
    },
    getUserVotes: () => {
        return axios.get( `${discussionForumBaseUrl}/user/votes`, { headers: headers() } )
    },
    uploadImage: ( payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/file/image-upload`, payload, { headers: headersFormdata() } )
    },
    uploadVideo: ( payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/file/video-upload`, payload, { headers: headersFormdata() } )
    },
    postComment: ( id, payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/${id}/comment`, payload, { headers: headers() } )
    },
    updateComment: ( id, payload ) => {
        return axios.put( `${discussionForumBaseUrl}/discussion/comment/${id}`, payload, { headers: headers() } )
    },
    voteComment: ( id, payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/comment/${id}/vote`, payload, { headers: headers() } )
    },
    voteDiscussion: ( id, payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/${id}/vote`, payload, { headers: headers() } )
    },
    deleteFile: ( payload ) => {
        return axios.post( `${discussionForumBaseUrl}/discussion/file/delete-file`, payload, { headers: headers() } )
    }
}

api.quiz = {
    createQuiz: ( payload ) => {
        return axios.post( `${examBaseURL}/quizzes/`, payload, { headers: headersFormdata() } )
    },
    fetchQuizes: ( params ) => {
        return axios.get( `${examBaseURL}/quizzes/`, { headers: headers(), params } )
    },
    fetchQuiz: ( id ) => {
        return axios.get( `${examBaseURL}/quizzes/${id}`, { headers: headers() } )
    },
    createQuizInstance: ( id, payload ) => {
        return axios.post( `${examBaseURL}/quizzes/${id}`, payload, { headers: headers() } )
    },
    rescheduleQuizInstance: ( id, instance_id, payload ) => {
        return axios.put( `${examBaseURL}/quizzes/${id}/instance/${instance_id}`, payload, { headers: headers() } )
    },
    startQuiz: ( quizId, instanceId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/start/${instanceId}`, { headers: headers() } )
    },
    fetchQuizQuestionsForAttempt: ( quizId, instanceId, attemptId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/instance/${instanceId}/attempt/${attemptId}`, { headers: headers() } )
    },
    fetchQuizInstances: ( quizId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/instance`, { headers: headers() } )
    },
    fetchAllInstances: () => {
        return axios.get( `${examBaseURL}/quizzes/instances`, { headers: headers() } )
    },
    submitAnswers: ( quizId, instanceId, attemptId, payload ) => {
        return axios.post( `${examBaseURL}/quizzes/${quizId}/instance/${instanceId}/attempt/${attemptId}`, payload, { headers: headers() } )
    },
    fetchAttemptScore: ( quizId, instanceId, attemptId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/instance/${instanceId}/attempt/${attemptId}/score`, { headers: headers() } )
    },
    fetchQuizInstanceScores: ( quizId, instanceId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/instance/${instanceId}/scores`, { headers: headers() } )
    },
    fetchQuizInstanceForStudent: ( quizId, instanceId ) => {
        return axios.get( `${examBaseURL}/quizzes/${quizId}/instance/${instanceId}`, { headers: headers() } )
    },
    addQuestionBank: ( payload ) => {
        return axios.post( `${examBaseURL}/questionbank/`, payload, { headers: headers() } )
    },
    createTopic: ( id, payload ) => {
        return axios.post( `${examBaseURL}/questionbank/${id}`, payload, { headers: headers() } )
    },
    deleteQuestionBank: ( id ) => {
        return axios.delete( `${examBaseURL}/questionbank/${id}`, { headers: headers() } )
    },
    deleteTopic: ( bankId, id ) => {
        return axios.delete( `${examBaseURL}/questionbank/${bankId}/topic/${id}`, { headers: headers() } )
    },
    deleteQuestion: ( bankId, topicId, id ) => {
        return axios.delete( `${examBaseURL}/questionbank/${bankId}/topic/${topicId}/question/${id}`, { headers: headers() } )
    },
    addQuestions: ( bankId, topicId, payload ) => {
        return axios.post( `${examBaseURL}/questionbank/${bankId}/topic/${topicId}`, payload, { headers: headers() } )
    },
    fetchQuestionBanks: ( params ) => {
        return axios.get( `${examBaseURL}/questionbank/`, { headers: headers(), params } )
    },
    fetchQuestionBank: ( id ) => {
        return axios.get( `${examBaseURL}/questionbank/${id}`, { headers: headers() } )
    },
    fetchQuestionBankQuestions: ( id ) => {
        return axios.get( `${examBaseURL}/questionbank/${id}/questions`, { headers: headers() } )
    },
    editQuestionbankName: ( id, payload ) => {
        return axios.put( `${examBaseURL}/questionbank/${id}`, payload, { headers: headers() } )
    },
    deleteQuiz: ( id ) => {
        return axios.delete( `${examBaseURL}/quizzes/${id}`, { headers: headers() } )
    },
    deleteInstance: ( quiz_id, instance_id ) => {
        return axios.delete( `${examBaseURL}/quizzes/${quiz_id}/instance/${instance_id}`, { headers: headers() } )
    }
}

api.liveSessions = {
    createLiveSession: ( payload ) => {
        return axios.post( `${liveSessionURL}/livesession`, payload, { headers: headers() } )
    },
    updateLiveSession: ( id, payload ) => {
        return axios.put( `${liveSessionURL}/livesession/${id}`, payload, { headers: headers() } )
    },
    fetchLiveSessions: ( params ) => {
        return axios.get( `${liveSessionURL}/livesession`, { headers: headers(), params } )
    },
    fetchLiveSession: ( id ) => {
        return axios.get( `${liveSessionURL}/livesession/${id}`, { headers: headers() } )
    },
    addResource: ( payload, progressFunc, downProgressFunc, signal ) => {
        return axios.post( `${liveSessionURL}/resource`, payload, { headers: headersFormdata(), onUploadProgress: progressFunc, onDownloadProgress: downProgressFunc, signal, timeout: 7200000 } )
    },
    linkResource: ( id, payload ) => {
        return axios.post( `${liveSessionURL}/livesession/${id}`, payload, { headers: headers() } )
    },
    unlinkResource: ( sessionId, relationId ) => {
        return axios.delete( `${liveSessionURL}/livesession/${sessionId}/unlink/${relationId}`, { headers: headers() } )
    },
    fetchResources: ( params ) => {
        return axios.get( `${liveSessionURL}/resource`, { headers: headers(), params } )
    },
    enableResource: ( sessionId, relationId ) => {
        return axios.put( `${liveSessionURL}/livesession/${sessionId}/enable/${relationId}`, {}, { headers: headers() } )
    },
    disableResource: ( sessionId, relationId ) => {
        return axios.put( `${liveSessionURL}/livesession/${sessionId}/disable/${relationId}`, {}, { headers: headers() } )
    },
    extractLinksMetadata: ( payload ) => {
        return axios.post( `${liveSessionURL}/resource/extract-metadata`, payload, { headers: headers() } )
    },
    fetchLiveSessionResourceLink: ( session_id, relation_id, payload, downloadProgress ) => {
        return axios.post( `${liveSessionURL}/livesession/${session_id}/resource/${relation_id}`, payload, { headers: headers(), onDownloadProgress: downloadProgress, onUploadProgress: downloadProgress } )
    },
    deleteResource: ( id ) => {
        return axios.delete( `${liveSessionURL}/resource/${id}`, { headers: headers() } )
    },
    deleteLiveSession: ( id ) => {
        return axios.delete( `${liveSessionURL}/livesession/${id}`, { headers: headers() } )
    },
}

api.aimentor = {
    saveSubjectSyllabus: ( subject_code, payload ) => {
        return axios.post( `${baseURL}/aimentor/syllabus/${subject_code}`, payload, { headers: headers() } )
    },
    getSubjectSyllabus: ( subject_code ) => {
        return axios.get( `${baseURL}/aimentor/syllabus/${subject_code}`, { headers: headers() } )
    },
    askAiMentor: ( payload ) => {
        return axios.post( `${baseURL}/aimentor/ask-mentor`, payload, { headers: headers() } )
    },
}

api.audit = {
    fetchUserDetails: ( auid ) => {
        return axios.get( `${baseURL}/audit/faculty/${auid}`, { headers: headers() } )
    },
    fetchInstituteDepartment: ( params ) => {
        return axios.get( `${baseURL}/audit/departments`, { headers: headers(), params } )
    },
    fetchFacultiesUnderAuditor: () => {
        return axios.get( `${baseURL}/audit/faculty`, { headers: headers() } )
    },

    // STUDY MATERIALS
    fetchStudyMaterialStats: ( params = {} ) => {
        return axios.get( `${studyMaterialBaseURL}/audit/stats`, { headers: headers(), params } )
    },
    fetchStudyMaterialCounts: ( params = {} ) => {
        return axios.get( `${studyMaterialBaseURL}/audit/counts`, { headers: headers(), params } )
    },
    getStudyMaterialGraph: ( params = {} ) => {
        return axios.get( `${studyMaterialBaseURL}/audit/graph`, { headers: headers(), params } )
    },
    getStudyMaterialsByFacultyId: ( auid ) => {
        return axios.get( `${studyMaterialBaseURL}/audit/faculty/${auid}`, { headers: headers() } )
    },
    getStudyMaterialsForAuditing: ( params = {} ) => {
        return axios.get( `${studyMaterialBaseURL}/audit/`, { headers: headers(), params } )
    },

    // ASSIGNMENTS
    getAssignmentStats: ( params = {} ) => {
        return axios.get( `${baseURL}/audit/assignment/facultystats`, { headers: headers(), params } )
    },
    getAssignmentsForAuditing: ( params = {} ) => {
        return axios.get( `${baseURL}/audit/assignment`, { headers: headers(), params } )
    },
    getAssignmentGraph: ( params = {} ) => {
        return axios.get( `${baseURL}/audit/assignment/graph`, { headers: headers(), params } )
    },
    getAssignmentCounts: ( params = {} ) => {
        return axios.get( `${baseURL}/audit/assignment/counts`, { headers: headers(), params } )
    },
    getAssignmentsByFacultyId: ( auid ) => {
        return axios.get( `${baseURL}/audit/assignment/faculty/${auid}`, { headers: headers() } )
    },

    // EXAMS
    getExamsStats: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/exam/stats`, { headers: headers(), params } )
    },
    getExamGraphData: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/exam/graph`, { headers: headers(), params } )
    },
    getExamsCounts: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/exam/counts`, { headers: headers(), params } )
    },
    getExamsByFacultyId: ( auid ) => {
        return axios.get( `${examBaseURL}/audit/exam/faculty/${auid}`, { headers: headers() } )
    },
    getExamsForAuditing: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/exam`, { headers: headers(), params } )
    },

    // QUIZZES
    getQuizzesStats: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/quiz/stats`, { headers: headers(), params } )
    },
    getQuizGraphData: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/quiz/graph`, { headers: headers(), params } )
    },
    getQuizzesCounts: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/quiz/counts`, { headers: headers(), params } )
    },
    getQuizzesByFacultyId: ( auid ) => {
        return axios.get( `${examBaseURL}/audit/quiz/faculty/${auid}`, { headers: headers() } )
    },
    getQuizzesForAuditing: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/quiz`, { headers: headers(), params } )
    },

    // QUIZZES
    getQuestionBanksStats: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/questionbank/stats`, { headers: headers(), params } )
    },
    getQuestionBanksCounts: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/questionbank/counts`, { headers: headers(), params } )
    },
    getQuestionBanksByFacultyId: ( auid ) => {
        return axios.get( `${examBaseURL}/audit/questionbank/faculty/${auid}`, { headers: headers() } )
    },
    getQuestionBanksForAuditing: ( params = {} ) => {
        return axios.get( `${examBaseURL}/audit/questionbank`, { headers: headers(), params } )
    },

    // DISCUSSIONS
    getDiscussionsStats: ( params = {} ) => {
        return axios.get( `${discussionForumBaseUrl}/audit/stats`, { headers: headers(), params } )
    },
    getDiscussionsCounts: ( params = {} ) => {
        return axios.get( `${discussionForumBaseUrl}/audit/counts`, { headers: headers(), params } )
    },
    getDiscussionsByFacultyId: ( auid ) => {
        return axios.get( `${discussionForumBaseUrl}/audit/faculty/${auid}`, { headers: headers() } )
    },
    getDiscussionsForAuditing: ( params = {} ) => {
        return axios.get( `${discussionForumBaseUrl}/audit/`, { headers: headers(), params } )
    },
}

api.admissionExams = {
    getUser: () => {
        return axios.get( `${examBaseURL}/admission-exams/user`, { headers: headers() } )
    },
    convertDocxToHtml: ( payload ) => {
        return axios.post( `${examBaseURL}/exams/convert-doc-to-txt`, payload, { headers: headersFormdata() } )
    },
    createExam: ( payload ) => {
        return axios.post( `${examBaseURL}/admission-exams/`, payload, { headers: headersFormdata() } )
    },
    updateAdmissionExam: ( id, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${id}`, payload, { headers: headers() } )
    },
    getAllExams: ( params ) => {
        return axios.get( `${examBaseURL}/admission-exams/`, { headers: headers(), params } )
    },
    getExam: ( id ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}`, { headers: headers() } )
    },
    getQuestionFile: ( id, fileId, responseType ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/questionfiles`, { headers: headers(), params: { fileId }, responseType } )
    },
    updateOptionText: ( exam_id, option_id, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${exam_id}/option/${option_id}`, payload, { headers: headers() } )
    },
    updateQuestion: ( exam_id, question_id, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${exam_id}/question/${question_id}`, payload, { headers: headers() } )
    },
    deleteExam: ( id ) => {
        return axios.delete( `${examBaseURL}/admission-exams/${id}`, { headers: headers(), } )
    },
    forceDeleteExam: ( id ) => {
        return axios.delete( `${examBaseURL}/admission-exams/${id}/force-delete`, { headers: headers(), } )
    },
    fetchExamParticipation: ( id ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/participation`, { headers: headers() } )
    },
    approveExam: ( id, payload ) => {
        return axios.post( `${examBaseURL}/admission-exams/${id}/approve`, payload, { headers: headers() } )
    },
    toggleClose: ( id, payload ) => {
        return axios.post( `${examBaseURL}/admission-exams/${id}/close`, payload, { headers: headers() } )
    },
    fetchParticipationData: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/participation/${pid}`, { headers: headers() } )
    },
    attempt: ( id ) => {
        return axios.post( `${examBaseURL}/admission-exams/${id}/attempt`, {}, { headers: headers() } )
    },
    fetchQuestions: ( id ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/questions`, { headers: headers() } )
    },
    fetchParticipationSubmissions: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/submissions/${pid}`, { headers: headers() } )
    },
    fetchEvaluationScheme: ( id ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/evaluationscheme`, { headers: headers() } )
    },
    fetchQuestionsForAttempt: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/questions/attempt/${pid}`, { headers: headers() } )
    },
    registerExamAttemptFault: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${id}/participation/${pid}/registerfault`, payload || {}, { headers: headers() } )
    },
    finishExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${id}/participation/${pid}/finish`, payload || {}, { headers: headers() } )
    },
    startExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}/admission-exams/${id}/start/${pid}`, payload || {}, { headers: headers() } )
    },
    submitAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}/admission-exams/${id}/submissions/${pid}`, payload, { headers: headers() } )
    },
    verifyCandidate: ( payload = {} ) => {
        return axios.post( `${examBaseURL}/admission-exams/verify-candidate`, payload, { headers: headers() } )
    },
    fetchParticipationQuestions: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/participation/${pid}/questions`, { headers: headers() } )
    },
    uploadWorkSheet: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}/admission-exams/${id}/${pid}/upload-worksheet`, payload, { headers: headersFormdata() } )
    },
    assignMarksToDescriptiveAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}/exams/${id}/submission/${pid}/assignmarks`, payload, { headers: headers() } )
    },
    getWorksheetUrl: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/${pid}/get-worksheet`, { headers: headers() } )
    },
    fetchCandidateParticipation: ( id, pid ) => {
        return axios.get( `${examBaseURL}/admission-exams/${id}/participation/${pid}`, { headers: headers() } )
    }
}

export default api