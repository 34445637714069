import { Box, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const DataLoadingDots = ( { dotSize = 5, spaceBetween = 5, color = 'primary', waitingMessage, padding = "5px" } ) => {

    const { palette } = useTheme()

    return (
        <Box padding={padding} display="flex" gap="10px" alignItems="center">
            <Box className="loading-dots" display="flex" gap={`${spaceBetween}px`}>
                <Box bgcolor={palette[color]?.main || color} width={`${dotSize}px`} borderRadius="50%" height={`${dotSize}px`} className="dot"></Box>
                <Box bgcolor={palette[color]?.main || color} width={`${dotSize}px`} borderRadius="50%" height={`${dotSize}px`} className="dot"></Box>
                <Box bgcolor={palette[color]?.main || color} width={`${dotSize}px`} borderRadius="50%" height={`${dotSize}px`} className="dot"></Box>
            </Box>
            {waitingMessage && <Typography fontSize="14px" fontWeight="500" color={color} variant='caption'>{waitingMessage}</Typography>}
        </Box>
    )
}

DataLoadingDots.propTypes = {
    dotSize: PropTypes.number.isRequired,
    spaceBetween: PropTypes.number.isRequired,
    color: PropTypes.string,
    padding: PropTypes.string,
    waitingMessage: PropTypes.string.isRequired,
}

export default DataLoadingDots
