import { Box, Button, FormControl, FormControlLabel, Select, TextField, Typography, MenuItem, Checkbox, CircularProgress, useTheme, Popover, RadioGroup, Radio } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { formatDateTimeForDB } from '../../utils/exam-utilities'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useOrganizationStore } from '../../store/organization.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import api from '../../service/api'

const hours = ["00", "01", "02", "03", "04", "05", "06"]
const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

const CreateExamComponent = observer( ( { setStepsStatus, setActiveStep, examCreationData, setExamCreationData } ) => {
    const initialDate = new Date().toISOString().split( 'T' )[0] + "T00:00"

    const [isOpenExam] = useState( true )
    const [studentBranches, setStudentBranches] = useState( [] )
    const [randomizeInfo, setRandomizeInfo] = useState( false )
    const [isCreatingExam, setIsCreatingExam] = useState( false )
    const [loadingInstitutes, setLoadingInstitutes] = useState( true )
    const [filteredBranches, setFilteredBranches] = useState( [] )
    const [receiveWorksheetPopperAnchorEl, setReceiveWorksheetPopperAnchorEl] = useState( null )
    const [enableMonitoringPopperAnchorEl, setEnableMonitoringPopperAnchorEl] = useState( null )
    const [durationHours, setDurationHours] = useState( examCreationData?.exam?.duration ? examCreationData?.exam?.duration?.split( ":" )[0] : "00" )
    const [durationMinutes, setDurationMinutes] = useState( examCreationData?.exam?.duration ? examCreationData?.exam?.duration?.split( ":" )[1] : "00" )

    const navigate = useNavigate()

    const OrganizationStore = useOrganizationStore()

    const { palette, border } = useTheme()


    const initialValues = examCreationData.exam || {
        title: "",
        description: "",
        topics: '',
        status: 'Draft',
        startDateTime: initialDate,
        endDateTime: new Date( `2099-01-01` ),
        duration: null,
        maximumAttempt: 1,
        randomize: false,
        receiveWorksheet: false,
        enableMonitoring: true,
        showScoresAfterFinishingExam: false,
        cutoff: 0,
        institute: "-",
        accessCourses: [],
        attemptsRule: "ONCE",
        whenToStart: 'approval'
    }


    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        cutoff: Yup.number().min( 0, "Minimum value: 0" ).max( 100, "Maximum value: 100" ),
        accessCourses: Yup.array().min( 1, "Please select atleast one course" ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid time format hours: HH and minutes: MM", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Invalid duration or duration exceeds the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const startDate = new Date( parent.startDateTime )
                const endDate = new Date( parent.endDateTime )
                const selectedDuration = ( endDate - startDate ) / 1000
                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] > 6 ) ) {
                    return false
                } else {
                    if ( val === "00:00" )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        ),
        startDateTime: Yup.date().test( "TEST_DATE", "Please enter valid date", ( val, { parent } ) => {
            if ( parent.whenToStart === 'approval' ) return true
            if ( val <= new Date() ) return false
            return true
        } ),
        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" ),
    } )

    const handleInstituteChange = ( val, setValue ) => {
        const filtered = []
        const selected = {}
        for ( const branch of studentBranches ) {
            if ( !selected[branch.course_branch_short_name] ) {
                filtered.push( branch )
                selected[branch.course_branch_short_name] = true
            }
        }
        setFilteredBranches( filtered )
        setValue( 'institute', val )
    }

    const handleCourseSelect = ( course, setValue ) => {
        setValue( 'accessCourses', typeof course === 'string' ? course.split( "," ) : course )
    }

    const submit = async ( values, { resetForm } ) => {
        try {
            setIsCreatingExam( true )
            const formData = new FormData()
            formData.append( 'title', values.title )
            formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
            formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
            formData.append( "status", values.status )
            formData.append( 'duration', isOpenExam ? values.duration : null )
            formData.append( 'maximumAttempt', values.maximumAttempt )
            formData.append( "randomize", values.randomize )
            formData.append( "enableMonitoring", values.enableMonitoring )
            formData.append( "receiveWorksheet", values.receiveWorksheet )
            formData.append( "showScoresAfterFinishingExam", values.showScoresAfterFinishingExam )
            formData.append( "exam_type", "global" )
            formData.append( "accessCourses", JSON.stringify( values.accessCourses ) )
            formData.append( "attemptsRule", values.attemptsRule )

            const creationData = {}
            creationData.exam = values
            creationData.formData = formData
            setExamCreationData( prev => {
                return { ...prev, ...creationData }
            } )
            setStepsStatus( prev => {
                prev[0] = "completed"
                return prev
            } )
            setActiveStep( prev => prev + 1 )
        } catch ( err ) {
            toast( err?.response?.data?.message || "An errorMessage occured while creating a new exam! try again later", { containerId: "main" } )
        } finally {
            setIsCreatingExam( false )
        }
    }

    useEffect( () => {
        const getOrganizations = async () => {
            await OrganizationStore.fetchOrganization()
            const { data: { data: branches } } = await api.fetchAcademicChecklistDepartments()

            setStudentBranches( branches )
            setLoadingInstitutes( false )
        }
        getOrganizations()
    }, [OrganizationStore] )

    return (
        <Box flexGrow={1} minWidth="0" overflow="auto" >

            <Box sx={{ paddingBottom: "1px" }}>
                <Box alignItems="center" display="flex" gap="10px" padding="20px">
                    <Box>
                        <Typography variant='h6' color="primaryDark" >New exam</Typography>
                        <Typography color="primaryDark" variant='body2' >Create exam by providing all required details</Typography>
                    </Box>
                </Box>
                <Box margin="20px" marginTop="0" bgcolor={palette.form.formBg} padding="20px" borderRadius="5px" border={border[1]}>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form method='POST' id='add-form' autoComplete='off' encType="multipart/form-data">

                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam title * </Typography></label>
                                    <Field placeholder="Eg: Kannada language internal test - 1" as={TextField} id="title" size="small" fullWidth name='title' />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='title' /></Typography>
                                </FormControl>

                                <Box display="flex" flexDirection="column" >
                                    <FormControl margin="normal" fullWidth>
                                        <label htmlFor="institute"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Select institute</Typography></label>
                                        <Field
                                            sx={{ color: values.institute === "-" && palette.form.placeholder, textTransform: "capitalize", fontSize: "14px" }}
                                            placeholder="Eg: Kannada language internal test - 1" as={Select}
                                            id="institute" size="small" onChange={e => handleInstituteChange( e.target.value, setFieldValue )} fullWidth name='institute' >
                                            <MenuItem disabled value="-" sx={{ fontSize: "14px" }}>{loadingInstitutes ? <DataLoadingSpinner padding='0' size={12} waitingMessage="Loading institutes..." /> : "Eg: Acharya institute of Technology (AIT)"}</MenuItem>
                                            {OrganizationStore.getOrganization.map( ( obj, i ) => {
                                                return <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={obj.institute_name_short} key={'key-' + i}>{obj.institute_name_short} - {obj.institute_name?.toLowerCase()} </MenuItem>
                                            } )}
                                        </Field>
                                    </FormControl>
                                    {values.institute !== "-" && <FormControl margin="normal" fullWidth>
                                        <label htmlFor="courses"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Select exam branches * </Typography></label>
                                        <Field
                                            displayEmpty
                                            renderValue={( selected ) => {
                                                if ( selected.length === 0 ) {
                                                    return <Typography fontSize="14px" color={palette.form.placeholder}>Please select the branches here, Eg: CS - computer science</Typography>
                                                }
                                                const selectedBranches = []
                                                for ( const branch of selected ) {
                                                    const branchDetails = studentBranches.find( b => b.course_branch_short_name === branch )
                                                    selectedBranches.push( `${branchDetails.course_branch_short_name} - ${branchDetails.course_branch_name?.toLowerCase()}` )
                                                }
                                                return selectedBranches.join( ', ' )
                                            }}
                                            onChange={e => handleCourseSelect( e.target.value, setFieldValue, values.accessCourses )} sx={{ color: values.accessCourses?.length === 0 && palette.form.placeholder, textTransform: "capitalize", fontSize: "14px" }} multiple as={Select} id="courses" size="small" value={values.accessCourses} fullWidth name='access_courses' >
                                            {filteredBranches.filter( b => b.institute_name_short === values.institute ).map( ( branch, i ) => {
                                                return <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={branch.course_branch_short_name} key={'key-' + i}> {branch.course_branch_short_name} - {branch.course_branch_name}</MenuItem>
                                            } )}
                                        </Field>
                                        <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='accessCourses' /></Typography>
                                    </FormControl>}
                                </Box>

                                {/* <Box onClick={e => setAccessSelectAnchorEl( accessCourseRef.current )} marginY="20px">
                                    <Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam courses * </Typography>
                                    <Box bgcolor={palette.form.inputBg} ref={accessCourseRef} sx={{ '&:hover': { borderColor: palette.common.font }, cursor: "pointer" }} display="flex" alignItems="center" minHeight="30px" borderRadius="5px" border={`1px solid ${palette.borderColor}`} padding="5px 10px">
                                        <Typography color={values.accessCourses.length === 0 && palette.form.placeholder} flexGrow={1}>{values.accessCourses.length > 0 ? values.accessCourses?.join( ", " ) : "Select courses to which this exam is conducted"}</Typography>
                                        {Icons.default.KeyboardArrowDownIcon}
                                    </Box>
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='accessCourses' /></Typography>
                                </Box>
                                <Popover onClose={() => setAccessSelectAnchorEl( null )} open={Boolean( accessSelectAnchorEl )} anchorEl={accessSelectAnchorEl}>
                                    <Box sx={{ pointerEvents: "none" }} maxHeight="240px" overflow="auto" className="custom-scrollbar" display="flex">
                                        {Object.keys( instituteCourseAndBranches )?.map( ( institute, index ) => (
                                            <Box display="flex" flexDirection="column" overflow="auto" key={institute}>
                                                <MenuItem dense sx={{ pointerEvents: "all", fontSize: "14px", '&.Mui-disabled': { opacity: 0.7 } }} disabled value={"-"}><Typography fontSize="inherit" fontWeight="700" color="secondary">{institute}</Typography></MenuItem>
                                                <Box className="custom-scrollbar" minWidth="150px" overflow="auto" sx={{ pointerEvents: "none" }} key={institute}>
                                                    {studentBranches.filter( b => b.institute_name_short === values.institute ).map( course => (
                                                        <MenuItem selected={values.accessCourses.includes( course.branch )} dense onClick={ } key={course.branch} value={course.branch} sx={{ pointerEvents: "all", fontSize: "14px" }} >{course.course_branch_name}</MenuItem>
                                                    ) )}
                                                </Box>
                                            </Box>
                                        ) )}
                                    </Box>
                                </Popover> */}

                                {isOpenExam && <FormControl variant='outlined' sx={{ display: "flex", marginTop: "10px" }}>
                                    <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">HOURS</Typography>} size="small" onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl >
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">MIN</Typography>} size="small" onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Typography variant="subtitle2" >Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}</Typography>
                                    <Typography fontSize="12px" color="errorMessage.main" ><ErrorMessage name='duration' /></Typography>
                                </FormControl>}


                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( randomizeInfo )} onClose={e => setRandomizeInfo( null )} anchorEl={randomizeInfo} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Randomize questions and options?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the questions and their options will be in random order for each individual taking the exam.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.randomize} onChange={e => { setFieldValue( 'randomize', e.target.checked ) }} />} label="Randamize questions and options?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setRandomizeInfo( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( receiveWorksheetPopperAnchorEl )} onClose={e => setReceiveWorksheetPopperAnchorEl( null )} anchorEl={receiveWorksheetPopperAnchorEl} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Receive worksheet after the completion of exam?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the candidates wil be asked to upload a worksheet in PDF format to show how thry have worked the solution for the problems.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.receiveWorksheet} onChange={e => { setFieldValue( 'receiveWorksheet', e.target.checked ) }} />} label="Receive worksheet after the completion of exam?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setReceiveWorksheetPopperAnchorEl( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                <Box marginTop="15px" display="flex" alignItems="center">
                                    <FormControlLabel control={<Checkbox color='primaryDark' checked={values.showScoresAfterFinishingExam} onChange={e => { setFieldValue( 'showScoresAfterFinishingExam', e.target.checked ) }} />} label="Show scores to candidates after finishing exams?" />
                                </Box>

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( enableMonitoringPopperAnchorEl )} onClose={e => setEnableMonitoringPopperAnchorEl( null )} anchorEl={enableMonitoringPopperAnchorEl} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Restrict candidate from moving away from the exam?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>Restricts the candidate from moving away from the exam tab, the exam will be finished if candidate tries to move away from the tab after fixed number of times.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.enableMonitoring} onChange={e => { setFieldValue( 'enableMonitoring', e.target.checked ) }} />} label="Restrict candidate from moving away from the exam?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setEnableMonitoringPopperAnchorEl( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                    <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>When to start exam?</Typography>
                                    <RadioGroup sx={{ gap: "10px" }} row name='whenToStart' value={values.whenToStart} color='primaryDark' onChange={e => { setFieldValue( 'whenToStart', e.target.value ); setFieldValue( 'startDateTime', initialDate ) }} >
                                        <FormControlLabel sx={{ bgcolor: palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: border[1], borderRadius: "5px" }} value="approval" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Immediately after exam approval</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>The exam will be available to candidates as soon as the exam is approved by the admin.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ bgcolor: palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: border[1], borderRadius: "5px" }} value="schedule" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Schedule start date and time.</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Exam will start at the specified date and time.</Typography>
                                                {values.whenToStart === 'schedule' && <Box marginTop="10px">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            id="startdatetime"
                                                            sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                            minDateTime={dayjs( new Date() )}
                                                            value={dayjs( values.startDateTime )}
                                                            format="DD-MM-YYYY hh:mm A"
                                                            onChange={( e ) => setFieldValue( 'startDateTime', e )}
                                                        />
                                                    </LocalizationProvider>
                                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='startDateTime' /></Typography>
                                                </Box>}
                                            </Box>
                                        } />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                    <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Attemp Rule</Typography>
                                    <RadioGroup sx={{ gap: "10px" }} row name='attemptsRule' value={values.attemptsRule} color='primaryDark' onChange={e => setFieldValue( 'attemptsRule', e.target.value )} >
                                        <FormControlLabel sx={{ bgcolor: palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: border[1], borderRadius: "5px" }} value="ONCE" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Only once</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Candidate is only allowed to take exam once.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ bgcolor: palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: border[1], borderRadius: "5px" }} value="UNTILLQUALIFY" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Untill qualify</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>The candidate can take exam repeateadly until he/she qualifies.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ bgcolor: palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: border[1], borderRadius: "5px" }} value="MAXATTEMPTS" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Maximum Attempts</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Candidate can only take exam specified number of times.</Typography>
                                                {values.attemptsRule === 'MAXATTEMPTS' && <Box marginTop="10px">
                                                    <Field as={TextField} name="maximumAttempt" type="number" size="small" inputProps={{ min: 1 }} />
                                                </Box>}
                                            </Box>
                                        } />
                                    </RadioGroup>
                                </FormControl>

                                <Box display="flex" gap="20px" marginTop="40px">
                                    <Button disableElevation disabled={isCreatingExam} type='submit' startIcon={isCreatingExam && <CircularProgress size={14} />} variant='contained' sx={{ textTransform: "capitalize" }}>{isCreatingExam ? "Creating exam..." : "Save and next"}</Button>
                                    <Button disableElevation color="error" onClick={() => navigate( '/admin/admission-exam' )} disabled={isCreatingExam} variant='contained' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    )
}

)

export default CreateExamComponent