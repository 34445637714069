import { Box, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'

const AiMentorChatTypeWriter = ( { containerRef, tagContentPairs = [], text, speed = 100 } ) => {
    const [typingCompleted, setTypingCompleted] = useState( false )
    const [currentTextIndex, setCurrentTextIndex] = useState( 0 )
    const [displayedHTML, setDisplayedHTML] = useState( '' )
    const [index, setIndex] = useState( 0 )

    const { theme } = useTheme()

    useEffect( () => {

        if ( index < tagContentPairs.length ) {
            const currentText = tagContentPairs[index].content || ""

            if ( containerRef ) {
                containerRef.scrollTop = containerRef.scrollHeight
            }

            const timeout = setTimeout( () => {

                setDisplayedHTML( prev => {
                    let displayData = prev
                    if ( tagContentPairs[index].type === 'text' ) {
                        displayData += currentText[currentTextIndex] || ""
                    } else {
                        displayData += currentText
                    }

                    return displayData
                } )
                if ( tagContentPairs[index].type === 'text' ) {
                    if ( currentTextIndex === currentText.length - 1 ) {
                        setIndex( index + 1 )
                        setCurrentTextIndex( 0 )
                    } else
                        setCurrentTextIndex( currentTextIndex + 1 )
                } else {
                    setIndex( index + 1 )
                    setCurrentTextIndex( 0 )
                }
            }, speed )

            return () => {
                clearTimeout( timeout )
            }
        } else {
            setTypingCompleted( true )
        }
    }, [index, speed, currentTextIndex, tagContentPairs, containerRef] )

    return (
        <Box className={`ai-response ${theme}`}>
            <Box dangerouslySetInnerHTML={{ __html: displayedHTML + ( typingCompleted ? "" : `<span class="blink">|</span>` ) }}></Box>
        </Box>
    )
}

export default AiMentorChatTypeWriter